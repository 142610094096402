interface ValidFileUploadType {
	mimeType: string | null;
	fileExtensions: string[];
	displayText: string;
	canPreview?: boolean;
}

export const VALID_FILE_UPLOAD_TYPES: ValidFileUploadType[] = [
	// Images
	{
		mimeType: 'image/png',
		fileExtensions: ['.png'],
		displayText: 'PNG',
		canPreview: true,
	},
	{
		mimeType: 'image/jpeg',
		fileExtensions: ['.jpg', '.jpeg'],
		displayText: 'JPG',
		canPreview: true,
	},
	{
		mimeType: 'image/webp',
		fileExtensions: ['.webp'],
		displayText: 'WEBP',
		canPreview: true,
	},
	{
		mimeType: 'image/tiff',
		fileExtensions: ['.tif', 'tiff'],
		displayText: 'TIF',
		canPreview: true,
	},
	{
		mimeType: 'image/gif',
		fileExtensions: ['.gif'],
		displayText: 'GIF',
		canPreview: true,
	},
	{
		mimeType: 'image/svg+xml',
		fileExtensions: ['.svg'],
		displayText: 'SVG',
		canPreview: true,
	},

	// PDFs
	{
		mimeType: 'application/pdf',
		fileExtensions: ['.pdf'],
		displayText: 'PDF',
		canPreview: true,
	},

	// Text files
	{
		mimeType: 'text/plain',
		fileExtensions: ['.txt'],
		displayText: 'Plain Text',
		canPreview: true,
	},
	{
		mimeType: 'application/vnd.apple.pages',
		fileExtensions: ['.pages', '.pages.zip'],
		displayText: 'Apple Pages',
	},
	{
		mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		fileExtensions: ['.docx'],
		displayText: 'Microsoft Word',
	},
	{
		mimeType: 'application/msword',
		fileExtensions: ['.doc'],
		displayText: 'Microsoft Word',
	},
	{
		mimeType: 'application/vnd.google-apps.document',
		fileExtensions: ['.gdoc'],
		displayText: 'Google Docs',
	},

	// Spreadsheets
	{
		mimeType: 'vnd.apple.numbers',
		fileExtensions: ['.numbers', '.numbers.zip'],
		displayText: 'Apple Numbers',
	},
	{
		mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		fileExtensions: ['.xlsx'],
		displayText: 'Microsoft Excel',
	},
	{
		mimeType: 'application/vnd.ms-excel',
		fileExtensions: ['.xls'],
		displayText: 'Microsoft Excel',
	},
	{
		mimeType: 'application/vnd.google-apps.spreadsheet',
		fileExtensions: ['.gsheet'],
		displayText: 'Google Sheets',
	},

	// SketchUp
	{
		mimeType: null,
		fileExtensions: ['.skp'],
		displayText: 'SketchUp',
	},
];

export const canUploadFileType = (mimeType: string, fileName: string) => {
	const isValidMimeType = VALID_FILE_UPLOAD_TYPES.some((fileType) => {
		return fileType.mimeType && fileType.mimeType === mimeType;
	});

	if (isValidMimeType) {
		return true;
	}

	const hasValidExtension = VALID_FILE_UPLOAD_TYPES.some((fileType) => {
		return fileType.fileExtensions.some((fileExtension) => {
			return fileName.endsWith(fileExtension);
		});
	});

	return hasValidExtension;
};

export const getDisplayType = (mimeType: string, fileName: string) => {
	return getFileType(mimeType, fileName)?.displayText ?? 'Unknown';
};

export const canPreviewFile = (mimeType: string, fileName: string): boolean => {
	return Boolean(getFileType(mimeType, fileName)?.canPreview);
};

export const getFileInputAccept = (): string => {
	return VALID_FILE_UPLOAD_TYPES
		.map((fileType) => {
			return [
				fileType.mimeType,
				fileType.fileExtensions,
			].filter(Boolean);
		})
		.flat(Infinity)
		.join(',');
};

const getFileType = (mimeType: string, fileName: string): ValidFileUploadType | null => {
	return VALID_FILE_UPLOAD_TYPES.find((fileType) => {
		if (fileType.mimeType && fileType.mimeType === mimeType) {
			return true;
		}

		for (const extension of fileType.fileExtensions) {
			if (fileName.endsWith(extension)) {
				return true;
			}
		}

		return false;
	}) ?? null;
};