export const orderBy = <T>(items: T[], property: string, direction: 'asc' | 'desc' = 'asc'): T[] => {
	if (items.length <= 1) {
		return items;
	}

	const firstValidItem: any = items.find((item: any) => {
		if (item[property] !== null && item[property] !== undefined) {
			return true;
		}

		return false;
	});

	const firstValidValue = firstValidItem?.[property];

	let compareFn: Function = () => 0;
	if (typeof firstValidValue === 'string') {
		if (direction === 'asc') {
			compareFn = (a: any, b: any) => {
				const aValue = ((a[property] ?? '') + '').toLocaleLowerCase();
				const bValue = ((b[property] ?? '') + '').toLocaleLowerCase();

				return aValue.localeCompare(bValue);
			};
		} else {
			compareFn = (a: any, b: any) => {
				const aValue = ((a[property] ?? '') + '').toLocaleLowerCase();
				const bValue = ((b[property] ?? '') + '').toLocaleLowerCase();

				return bValue.localeCompare(aValue);
			};
		}
	} else if (typeof firstValidValue === 'number') {
		if (direction === 'asc') {
			compareFn = (a: any, b: any) => a[property] - b[property];
		} else {
			compareFn = (a: any, b: any) => b[property] - a[property];
		}
	}

	return items.slice().sort(compareFn as any);
};