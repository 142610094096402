import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import InvoiceTable from './InvoiceTable';
import {Button, Icon, Loader} from '@sproutsocial/racine';
import Layout from '../Layout';

const GET_INVOICES = gql(/* GraphQL */ `
	query GetInvoicesForInvoiceList {
		invoices {
			id
			date
			customer {
				id
				name
			}
			items {
				type
				quantity
				price
				taxed
			}
		}
	}
`);

const InvoiceList = () => {
	const {data: {invoices} = {}} = useQuery(GET_INVOICES);

	if (!invoices) {
		return <Loader />;
	}

	return (
		<Layout>
			<Layout.Header title='Invoices'>
				<Button appearance='primary' href='/invoices/new'>
					<Icon name='plus' mr='space.300' /> New Invoice
				</Button>
			</Layout.Header>
			<Layout.Body>
				<InvoiceTable invoices={invoices} />
			</Layout.Body>
		</Layout>
	);
};

export default InvoiceList;
