import {useCallback, type SyntheticEvent} from 'react';
import {useQuery} from '@apollo/client';
import {Box, Checkbox} from '@sproutsocial/racine';
import {gql} from '../../graphql';

interface ProjectPickerProps {
	selectedProjectIds: Set<number>;
	indeterminateProjectIds?: Set<number>;
	onProjectDeselected: (projectId: number) => void;
	onProjectSelected: (projectId: number) => void;
}

const GET_PROJECTS = gql(/* GraphQL */ `
	query GetProjectsForPicker {
		projects {
			id
			name
		}
	}
`);

const ProjectPicker = (props: ProjectPickerProps) => {
	const {data: {projects} = {}} = useQuery(GET_PROJECTS);

	const {selectedProjectIds, indeterminateProjectIds, onProjectDeselected, onProjectSelected} =
		props;

	const onCheckboxChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const tagId = parseInt(event.currentTarget.value, 10);

			if (selectedProjectIds.has(tagId)) {
				if (indeterminateProjectIds?.has(tagId)) {
					onProjectSelected(tagId);
				} else {
					onProjectDeselected(tagId);
				}
			} else {
				onProjectSelected(tagId);
			}
		},
		[selectedProjectIds, indeterminateProjectIds, onProjectSelected, onProjectDeselected],
	);

	return (
		<Box display='flex' flexDirection='column'>
			{projects?.map((project) => (
				<Box key={project.id} display='flex' alignItems='center' mb='space.200'>
					<Checkbox
						id={`checkbox-${project.id}`}
						checked={selectedProjectIds.has(project.id)}
						indeterminate={indeterminateProjectIds?.has(project.id)}
						value={String(project.id)}
						onChange={onCheckboxChange}
						mr='space.300'
					/>

					{project.name}
				</Box>
			))}
		</Box>
	);
};

export default ProjectPicker;
