import {SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {Box, Button, Icon, Input, LoaderButton} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import TransactionsTable from './TransactionsTable';
import DatePicker from '../DatePicker';
import CategoryPicker from '../CategoryPicker';
import {useDebouncedCallback, useLocalStorageState} from '../../hooks';
import {gql} from '../../graphql';
import TagPickerModal from '../tags/TagPickerModal';
import {useQuery} from '@apollo/client';
import {downloadTransactionCsv} from '../../util/csv';
import ProjectPickerModal from '../forms/ProjectPickerModal';
import Layout from '../Layout';
import {useIsMobile} from '../../util/mobile';
import {orderBy} from 'shared/src/list';

const GET_TRANSACTIONS = gql(/* GraphQL */ `
	query GetTransactionsForUserFilters(
		$startDate: String
		$endDate: String
		$categoryIds: [Int!]
		$tagIds: [Int!]
		$excludedTagIds: [Int!]
		$projectIds: [Int!]
	) {
		transactions(
			startDate: $startDate
			endDate: $endDate
			categoryIds: $categoryIds
			tagIds: $tagIds
			excludedTagIds: $excludedTagIds
			projectIds: $projectIds
		) {
			id
			date
			amount
			memo
			payee
			paymentAccount {
				id
				name
			}
			tags {
				id
				name
				color
			}
			projects {
				id
				name
			}
			category {
				id
				name
				hidden
				priority
				categoryGroup {
					id
					name
				}
			}
		}
	}
`);

const TransactionsList = () => {
	const isMobile = useIsMobile();

	const [startDate, setStartDate] = useLocalStorageState<DateTime>({
		localStorageKey: 'transaction-list.start-date',
		defaultValue: DateTime.now().startOf('month'),
		serialize: (datetime) => datetime.toISO(),
		deserialize: (json) => DateTime.fromISO(json),
	});
	const [endDate, setEndDate] = useLocalStorageState<DateTime>({
		localStorageKey: 'transaction-list.end-date',
		defaultValue: DateTime.now(),
		serialize: (datetime) => datetime.toISO(),
		deserialize: (json) => DateTime.fromISO(json),
	});

	const [selectedCategoryIds, setSelectedCategoryIds] = useLocalStorageState<Array<number>>({
		localStorageKey: 'transaction-list.categories',
		defaultValue: [],
	});

	const [selectedProjectIds, setSelectedProjectIds] = useLocalStorageState<Set<number>>({
		localStorageKey: 'transaction-list.projects',
		defaultValue: new Set(),
		serialize: (set) => JSON.stringify(Array.from(set)),
		deserialize: (json) => new Set<number>(JSON.parse(json) as number[]),
	});

	const [selectedTagIds, setSelectedTagIds] = useLocalStorageState<Set<number>>({
		localStorageKey: 'transaction-list.tags',
		defaultValue: new Set(),
		serialize: (set) => JSON.stringify(Array.from(set)),
		deserialize: (json) => new Set<number>(JSON.parse(json) as number[]),
	});

	const [excludedTagIds, setExcludedTagIds] = useLocalStorageState<Set<number>>({
		localStorageKey: 'transaction-list.excluded-tags',
		defaultValue: new Set(),
		serialize: (set) => JSON.stringify(Array.from(set)),
		deserialize: (json) => new Set<number>(JSON.parse(json) as number[]),
	});

	const [filter, setFilter] = useState('');
	const [normalizedFilter, setNormalizedFilter] = useState(filter);

	const {data: {transactions: rawTransactions} = {}, refetch: refetchTransactions} = useQuery(
		GET_TRANSACTIONS,
		{
			variables: {
				startDate: startDate.toFormat('yyyy-LL-dd'),
				endDate: endDate.toFormat('yyyy-LL-dd'),
				categoryIds: selectedCategoryIds,
				tagIds: Array.from(selectedTagIds),
				excludedTagIds: Array.from(excludedTagIds),
				projectIds: Array.from(selectedProjectIds),
			},
		},
	);

	const transactions = useMemo(() => {
		if (!rawTransactions) {
			return;
		}

		const normalizedTransactions = rawTransactions.filter((transaction) => {
			if (!normalizedFilter) {
				return true;
			}

			return (
				transaction.payee?.toLocaleLowerCase().includes(normalizedFilter) ||
				transaction.memo.toLocaleLowerCase().includes(normalizedFilter)
			);
		});

		return orderBy(normalizedTransactions, 'date', 'desc');
	}, [rawTransactions, normalizedFilter]);

	const tableKey = useMemo(() => {
		return transactions
			?.map((transaction) => transaction.id)
			.join();
	}, [transactions]);

	const [isCategoryPickerOpen, setIsCategoryPickerOpen] = useState(false);
	const openCategoryPicker = useCallback(
		() => setIsCategoryPickerOpen(true),
		[setIsCategoryPickerOpen],
	);
	const onCategoryPickerClose = useCallback(
		(newSelectedCategoryIds: Array<number>) => {
			setSelectedCategoryIds(newSelectedCategoryIds);
			setIsCategoryPickerOpen(false);
		},
		[setSelectedCategoryIds],
	);

	const [isProjectPickerOpen, setIsProjectPickerOpen] = useState(false);
	const openProjectPicker = useCallback(() => setIsProjectPickerOpen(true), [setIsProjectPickerOpen]);
	const onProjectPickerSave = useCallback(
		(newSelectedProjectIds: Set<number>) => {
			setSelectedProjectIds(newSelectedProjectIds);
			setIsProjectPickerOpen(false);
		},
		[setSelectedProjectIds],
	);
	const onProjectPickerCancel = useCallback(() => {
		setIsProjectPickerOpen(false);
	}, []);

	const [isTagPickerOpen, setIsTagPickerOpen] = useState(false);
	const openTagPicker = useCallback(() => setIsTagPickerOpen(true), [setIsTagPickerOpen]);
	const onTagPickerSave = useCallback(
		(newSelectedTagIds: Set<number>, newExcludedTagIds: Set<number>) => {
			setSelectedTagIds(newSelectedTagIds);
			setExcludedTagIds(newExcludedTagIds);
			setIsTagPickerOpen(false);
		},
		[setSelectedTagIds, setExcludedTagIds],
	);
	const onTagPickerCancel = useCallback(() => {
		setIsTagPickerOpen(false);
	}, []);

	const updateNormalizedFilter = useDebouncedCallback(
		(filter: string) => {
			setNormalizedFilter(filter.trim().toLocaleLowerCase());
		},
		500,
		[setNormalizedFilter],
	);

	const onFilterChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setFilter(event.currentTarget.value);
			updateNormalizedFilter(event.currentTarget.value);
		},
		[setFilter, updateNormalizedFilter],
	);

	const [csvGenerating, setCsvGenerating] = useState<boolean>(false);
	const onDownloadCsv = useCallback(() => {
		if (transactions) {
			setCsvGenerating(true);
			downloadTransactionCsv(transactions, 'transactions.csv');
			setCsvGenerating(false);
		}
	}, [transactions, setCsvGenerating]);

	return (
		<Layout>
			<Layout.Header title='Transactions'>
				<Box display={isMobile ? 'none' : 'flex'}>
					<Box display='flex' alignItems='center' mr='space.300'>
						<DatePicker value={startDate} onChange={setStartDate} />
						<Box mx='space.300'>&mdash;</Box>
						<DatePicker value={endDate} onChange={setEndDate} />
					</Box>
					<Button appearance='secondary' onClick={openCategoryPicker} mr='space.300'>
						<Box display='flex' alignItems='center'>
							<Icon name='folder' size='mini' mr='space.300' /> Categories
						</Box>
					</Button>
					<Button appearance='secondary' onClick={openProjectPicker} mr='space.300'>
						<Box display='flex' alignItems='center'>
							<Icon name='screwdriver-hammer-solid' size='mini' mr='space.300' /> Projects
						</Box>
					</Button>
					<Button appearance='secondary' onClick={openTagPicker} mr='space.300'>
						<Box display='flex' alignItems='center'>
							<Icon name='tag' size='mini' mr='space.300' /> Tags
						</Box>
					</Button>
					<Input
						id='transaction-filter'
						name='transaction-filter'
						placeholder='Search transactions...'
						value={filter}
						onChange={onFilterChange}
						mr='space.300'
					/>
					<LoaderButton
						appearance='secondary'
						isLoading={csvGenerating}
						onClick={onDownloadCsv}
						mr='space.300'
					>
						<Icon name='arrow-up-from-bracket-solid' mr='space.300' /> Export CSV
					</LoaderButton>
				</Box>
			</Layout.Header>
			<Layout.Body>
				{transactions && (
					<TransactionsTable
						id='main-transactions'
						key={tableKey}
						transactions={transactions}
						onChange={refetchTransactions}
					/>
				)}

				{isCategoryPickerOpen && (
					<CategoryPicker selectedCategoryIds={selectedCategoryIds} onClose={onCategoryPickerClose} />
				)}

				{isProjectPickerOpen && (
					<ProjectPickerModal
						title='Filter Projects'
						selectedProjectIds={selectedProjectIds}
						onSave={onProjectPickerSave}
						onCancel={onProjectPickerCancel}
					/>
				)}

				{isTagPickerOpen && (
					<TagPickerModal
						selectedTagIds={selectedTagIds}
						allowExclusions
						excludedTagIds={excludedTagIds}
						onSave={onTagPickerSave}
						onCancel={onTagPickerCancel}
					/>
				)}
			</Layout.Body>
		</Layout>
	);
};

export default TransactionsList;
