import {useCallback, useMemo, type SyntheticEvent} from 'react';
import {Box, Button, Checkbox, Icon, Modal, Text} from '@sproutsocial/racine';
import {useSetState} from '../../hooks';
import TagPicker from './TagPicker';
import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';

const GET_TAGS = gql(/* GraphQL */ `
	query GetTagsIdsForTagPickerModal {
		tags {
			id
		}
	}
`);

interface TagPickerModalProps {
	selectedTagIds: Set<number>;
	excludedTagIds?: Set<number>;
	allowExclusions?: boolean;
	onSave(selectedTagIds: Set<number>, excludedTagIds?: Set<number>): void;
	onCancel(): void;
}

const TagPickerModal = (props: TagPickerModalProps) => {
	const {onSave, onCancel} = props;

	const {data: {tags} = {}} = useQuery(GET_TAGS);

	const selectedTagIds = useSetState(props.selectedTagIds);
	const excludedTagIds = useSetState(props.excludedTagIds);

	const onTagIncluded = useCallback(
		(tagId: number) => {
			selectedTagIds.add(tagId);
		},
		[selectedTagIds],
	);

	const onTagUnincluded = useCallback(
		(tagId: number) => {
			selectedTagIds.delete(tagId);
		},
		[selectedTagIds],
	);

	const onTagExcluded = useCallback(
		(tagId: number) => {
			excludedTagIds.add(tagId);
		},
		[excludedTagIds],
	);

	const onTagUnexcluded = useCallback(
		(tagId: number) => {
			excludedTagIds.delete(tagId);
		},
		[excludedTagIds],
	);

	const allTagsExcluded = useMemo<boolean>(() => {
		return tags?.every((tag) => excludedTagIds.has(tag.id)) ?? false;
	}, [tags, excludedTagIds]);

	const onExcludeAll = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			if (allTagsExcluded) {
				excludedTagIds.clear();
			} else {
				tags?.forEach((tag) => {
					if (!excludedTagIds.has(tag.id)) {
						excludedTagIds.add(tag.id);
					}
				});
			}
		},
		[allTagsExcluded, tags, excludedTagIds],
	);

	const handleSave = useCallback(() => {
		onSave(new Set(selectedTagIds), new Set(excludedTagIds));
	}, [onSave, selectedTagIds, excludedTagIds]);

	return (
		<Modal isOpen>
			<Modal.Header title='Filter Tags' />

			<Modal.Content>
				<Text as='div' fontWeight='semibold' mb='space.300'>
					Must Have
				</Text>
				<Box display='flex' flexDirection='column'>
					<TagPicker
						selectedTagIds={selectedTagIds}
						onTagDeselected={onTagUnincluded}
						onTagSelected={onTagIncluded}
					/>
				</Box>

				{props.allowExclusions && (
					<>
						<Box borderBottom='500' borderColor='container.border.base' my='space.400' />

						<Text as='div' fontWeight='semibold' mb='space.300'>
							Can't Have
						</Text>
						<Box display='flex' flexDirection='column'>
							<Box display='flex' alignItems='center' mb='space.200'>
								<Checkbox
									id='exclude-all-tags'
									checked={excludedTagIds.size > 0}
									indeterminate={!allTagsExcluded && excludedTagIds.size > 0}
									onChange={onExcludeAll}
									mr='space.300'
								/>
								<Icon name='tag' color='neutral.1000' mr='space.300' />
								Exclude All
							</Box>

							<TagPicker
								selectedTagIds={excludedTagIds}
								onTagDeselected={onTagUnexcluded}
								onTagSelected={onTagExcluded}
							/>
						</Box>
					</>
				)}
			</Modal.Content>

			<Modal.Footer>
				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					<Button mr='space.300' onClick={onCancel}>
						Cancel
					</Button>
					<Button appearance='primary' onClick={handleSave} width={100}>
						Save
					</Button>
				</Box>
			</Modal.Footer>
		</Modal>
	);
};

export default TagPickerModal;
