import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import {Loader} from '@sproutsocial/racine';
import Layout from '../Layout';
import ProjectsTable from '../projects/ProjectsTable';

const GET_CUSTOMER_PROJECTS = gql(/* GraphQL */ `
	query GetCustomerProjects($customerId: String!) {
		customer(id: $customerId) {
			name
			projects {
				id
				name
				description
				startDate
			}
		}
	}
`);

interface CustomerInvoiceListProps {
	customerId: string;
}

const CustomerInvoiceList = ({customerId}: CustomerInvoiceListProps) => {
	const {data: {customer} = {}} = useQuery(GET_CUSTOMER_PROJECTS, {
		variables: {
			customerId,
		},
	});

	if (!customer) {
		return <Loader />;
	}

	return (
		<Layout>
			<Layout.Header title={`Projects for ${customer.name}`} />
			<Layout.Body>
				<ProjectsTable projects={customer.projects} showCustomerName={false} />
			</Layout.Body>
		</Layout>
	);
};

export default CustomerInvoiceList;
