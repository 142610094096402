import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router';
import {
	Loader,
	ThemeProvider,
	ToastContainer
} from '@sproutsocial/racine';
import {ApolloProvider} from '@apollo/client';
import {createGlobalStyle} from 'styled-components';
import {
	ExternalSprite as ExternalIconsHtml,
	GeneralSprite as GeneralIconsHtml,
	SproutSprite as SproutIconsHtml,
} from '@sproutsocial/seeds-icons';
import {Sprite as IllustrationsHtml} from '@sproutsocial/seeds-illustrations';
import router from './router';
import {apolloClient} from './graphql';
import {initializeApp} from './init';
// @ts-ignore
import LinkContainer from '@sproutsocial/racine/lib/Link/styles';

await initializeApp();

const GlobalStyles = createGlobalStyle`
	html, body, #root {
		width: 100%;
		height: 100%;
	}

	body {
		margin: 0;
		font-family: ${({theme}) => theme.fontFamily};
		background: ${({theme}) => theme.colors.neutral[100]};
	}

	${LinkContainer} {
		text-decoration: none;
	}
`;

const root = createRoot(document.getElementById('root')!);

root.render(
	<React.StrictMode>
		<ThemeProvider>
			<ApolloProvider client={apolloClient}>
				<GlobalStyles />

				<div style={{display: 'none'}}>
					<div dangerouslySetInnerHTML={{__html: ExternalIconsHtml}} />
					<div dangerouslySetInnerHTML={{__html: GeneralIconsHtml}} />
					<div dangerouslySetInnerHTML={{__html: SproutIconsHtml}} />
					<div dangerouslySetInnerHTML={{__html: IllustrationsHtml}} />
				</div>

				<RouterProvider
					router={router}
					fallbackElement={<Loader delay />}
				/>

				<ToastContainer />
			</ApolloProvider>
		</ThemeProvider>
	</React.StrictMode>
);
