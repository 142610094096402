import {type SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {
	Box,
	Icon,
	Input,
	Listbox,
	ListboxButton,
	LoaderButton,
	Text,
	Tooltip,
} from '@sproutsocial/racine';
import {useMutation, useQuery} from '@apollo/client';
import TagToken from './TagToken';
import {gql} from '../../graphql';
import TagName from './TagName';

const TAG_COLORS = [
	{name: 'Red', value: 'red.500'},
	{name: 'Blue', value: 'blue.500'},
	{name: 'Yellow', value: 'yellow.500'},
	{name: 'Purple', value: 'purple.500'},
	{name: 'Green', value: 'green.500'},
	{name: 'Orange', value: 'orange.500'},
	{name: 'Pink', value: 'pink.500'},
	{name: 'Magenta', value: 'magenta.500'},
	{name: 'Gray', value: 'neutral.500'},
	{name: 'Teal', value: 'teal.500'},
	{name: 'Aqua', value: 'aqua.500'},
];

const GET_TAGS = gql(/* GraphQL */ `
	query GetTags {
		tags {
			id
		}
	}
`);

const CREATE_TAG = gql(/* GraphQL */ `
	mutation CreateTag($newTag: NewTagInput) {
		createTag(newTag: $newTag) {
			id
		}
	}
`);

const TagManagement = () => {
	const {data: queryData} = useQuery(GET_TAGS);
	const tags = queryData?.tags;

	const [tagName, setTagName] = useState('');
	const onTagNameChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setTagName(event.currentTarget.value);
		},
		[setTagName],
	);

	const [selectedColor, setSelectedColor] = useState('red.500');

	const [parentTagId, setParentTagId] = useState<string>('0');
	const parentTag = useMemo(() => {
		if (!tags || !parentTagId) {
			return null;
		}

		return tags.find((tag) => tag.id === parseInt(parentTagId, 10))!;
	}, [tags, parentTagId]);

	const [createTag, {loading: isCreating}] = useMutation(CREATE_TAG);

	const onCreateTag = useCallback(async () => {
		await createTag({
			variables: {
				newTag: {
					name: tagName,
					color: selectedColor,
					parentTagId: parseInt(parentTagId, 10) || null,
				},
			},
			refetchQueries: [GET_TAGS],
		});

		setTagName('');
	}, [createTag, tagName, selectedColor, parentTagId]);

	return (
		<Box p='space.350'>
			<Box>
				<Text as='div' fontSize='500' mb='space.300'>
					Tags
				</Text>

				<Box
					width='100%'
					border='500'
					borderColor='container.border.base'
					borderRadius='outer'
					bg='white'
					p='space.300'
					pb='0'
					display='flex'
					flexWrap='wrap'
				>
					{tags?.map((tag) => (
						<Tooltip key={tag.id} content={tag.id}>
							<TagToken id={tag.id} closeable={false} mr='space.300' mb='space.300' />
						</Tooltip>
					))}
				</Box>
			</Box>
			<Box mt='space.400'>
				<Text as='div' fontSize='300' mb='space.300'>
					Create Tag
				</Text>
				<Box>
					<Box display='flex' flexDirection='column'>
						<Box display='flex' flexDirection='column' mb='space.300'>
							<Text fontSize='200' fontWeight='semibold' color='text.body' mr='space.300'>
								Name
							</Text>
							<Box width={300}>
								<Input
									id='newTagName'
									name='newTagName'
									value={tagName}
									onChange={onTagNameChange}
									mr='space.400'
								/>
							</Box>
						</Box>
						<Box display='flex' flexDirection='column' mb='space.300'>
							<Text fontSize='200' fontWeight='semibold' color='text.body'>
								Parent Tag
							</Text>
							<ListboxButton
								width={300}
								content={
									<Listbox value={String(parentTagId)} onChange={setParentTagId} width={400}>
										<Listbox.Group>
											<Listbox.Item key={0} value={'0'} children={<div>&nbsp;</div>} />
											{tags?.map((tag) => (
												<Listbox.Item key={tag.id} value={String(tag.id)}>
													<TagName id={tag.id} showIcon />
												</Listbox.Item>
											))}
										</Listbox.Group>
									</Listbox>
								}
							>
								<Box display='flex' alignItems='center'>
									{!parentTag && <div>&nbsp;</div>}
									{parentTag && (
										<>
											<TagName id={parentTag.id} showIcon />
										</>
									)}
								</Box>
							</ListboxButton>
						</Box>
						<Box display='flex' flexDirection='column' mb='space.300'>
							<Text fontSize='200' fontWeight='semibold' color='text.body'>
								Color
							</Text>
							<ListboxButton
								width={150}
								content={
									<Listbox value={selectedColor} onChange={setSelectedColor} width={200}>
										<Listbox.Group>
											{TAG_COLORS.map(({name, value}) => (
												<Listbox.Item
													key={value}
													value={value}
													elemBefore={<Icon name='tag' color={value} mr='space.300' />}
												>
													{name}
												</Listbox.Item>
											))}
										</Listbox.Group>
									</Listbox>
								}
							>
								<Box display='flex' alignItems='center'>
									<Icon name='tag' color={selectedColor} mr='space.300' />
									{TAG_COLORS.find(({value}) => value === selectedColor)?.name}
								</Box>
							</ListboxButton>
						</Box>
						<LoaderButton
							appearance='primary'
							width={100}
							isLoading={isCreating}
							onClick={onCreateTag}
						>
							Create
						</LoaderButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default TagManagement;
