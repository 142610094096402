import {Box, Button, Checkbox, Modal, Text} from '@sproutsocial/racine';
import {Fragment, SyntheticEvent, useCallback, useState} from 'react';
import {useQuery} from '@apollo/client';
import {gql, type Category, type CategoryGroup} from '../graphql';

const filterHidden = (item: Category | CategoryGroup) => !item.hidden;

interface CategoryPickerProps {
	selectedCategoryIds: Array<number>;
	onClose(selectedCategoryIds: Array<number>): void;
}

const GET_CATEGORIES = gql(/* GraphQL */ `
	query GetCategories {
		categoryGroups {
			id
			name
			hidden
			priority
			categories {
				id
				name
				hidden
				priority
			}
		}
	}
`);

const CategoryPicker = ({
	onClose,
	selectedCategoryIds: originalSelectedCategoryIds,
}: CategoryPickerProps) => {
	const {data: queryData} = useQuery(GET_CATEGORIES);
	const categoryGroups = queryData?.categoryGroups;

	const [selectedCategoryIds, setSelectedCategoryIds] = useState(originalSelectedCategoryIds);

	const onCheckboxChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			if (!categoryGroups) {
				return;
			}

			const categoryId = parseInt(event.currentTarget.value, 10);

			const newSelectedCategoryIds = new Set(selectedCategoryIds);

			const categoryGroup = categoryGroups.find((categoryGroup) => categoryGroup.id === categoryId);

			if (categoryGroup) {
				if (categoryGroup.categories?.every((child) => selectedCategoryIds.includes(child.id))) {
					categoryGroup.categories?.forEach((child) => newSelectedCategoryIds.delete(child.id));
				} else {
					categoryGroup.categories?.forEach((child) => newSelectedCategoryIds.add(child.id));
				}
			} else {
				if (newSelectedCategoryIds.has(categoryId)) {
					newSelectedCategoryIds.delete(categoryId);
				} else {
					newSelectedCategoryIds.add(categoryId);
				}
			}

			setSelectedCategoryIds(Array.from(newSelectedCategoryIds));
		},
		[categoryGroups, selectedCategoryIds, setSelectedCategoryIds],
	);

	const onCancel = useCallback(() => {
		onClose(originalSelectedCategoryIds);
	}, [onClose, originalSelectedCategoryIds]);

	const onSave = useCallback(() => {
		onClose(selectedCategoryIds);
	}, [onClose, selectedCategoryIds]);

	return (
		<Modal isOpen label='Category Picker' closeButtonLabel='Close Category Picker'>
			<Modal.Header title='Filter Categories' />

			<Modal.Content>
				<Box>
					{categoryGroups?.filter(filterHidden).map((categoryGroup) => {
						const allChildrenSelected = categoryGroup.categories?.every((category) =>
							selectedCategoryIds.includes(category.id),
						);
						const someChildrenSelected = categoryGroup.categories?.some((category) =>
							selectedCategoryIds.includes(category.id),
						);

						return (
							<Fragment key={categoryGroup.id}>
								<Box display='flex' alignItems='center'>
									<Checkbox
										id={`checkbox-${categoryGroup.id}`}
										checked={someChildrenSelected}
										indeterminate={!allChildrenSelected && someChildrenSelected}
										value={String(categoryGroup.id)}
										onChange={onCheckboxChange}
										mr='space.300'
									/>
									<Text fontSize='200' fontWeight='semibold' color='headline'>
										{categoryGroup.name}
									</Text>
								</Box>
								{categoryGroup.categories?.filter(filterHidden).map((category) => (
									<Box key={category.id} display='flex' alignItems='center' pl='space.400'>
										<Checkbox
											id={`checkbox-${category.id}`}
											checked={selectedCategoryIds.includes(category.id)}
											value={String(category.id)}
											onChange={onCheckboxChange}
											mr='space.300'
										/>
										<Text fontSize='200' color='headline'>
											{category.name}
										</Text>
									</Box>
								))}
							</Fragment>
						);
					})}
				</Box>
			</Modal.Content>

			<Modal.Footer>
				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					<Button mr='space.300' onClick={onCancel}>
						Cancel
					</Button>
					<Button appearance='primary' onClick={onSave} width={100}>
						Save
					</Button>
				</Box>
			</Modal.Footer>
		</Modal>
	);
};

export default CategoryPicker;
