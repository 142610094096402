import {salesTaxRate} from './constants';
import {type InvoiceLineItem, InvoiceLineItemType} from './graphql';

type MinimumLineItem = Pick<InvoiceLineItem, 'type' | 'price' | 'quantity' | 'taxed'>;

export const getInvoiceTotal = (lineItems: MinimumLineItem[]): number => {
	return getInvoiceSubtotal(lineItems) + getInvoiceTaxes(lineItems);
};

/**
 * A subtotal doesn't include payments, it shows the COST of the invoice not what is owed
 */
export const getInvoiceSubtotal = (lineItems: MinimumLineItem[]): number => {
	return lineItems.reduce((subtotal, item) => {
		const itemCost = getLineItemCost(item);

		switch (item.type) {
			case InvoiceLineItemType.Cost:
				return subtotal + itemCost;
			case InvoiceLineItemType.Credit:
				return subtotal - itemCost;
			case InvoiceLineItemType.Payment:
				return subtotal;
		}

		throw new Error('A new InvoiceLineItemType was added but not handled');
	}, 0);
};

export const getInvoiceTaxes = (lineItems: MinimumLineItem[]): number => {
	return lineItems.reduce((taxes, item) => {
		return taxes + Math.round(item.taxed ? getLineItemCost(item) * salesTaxRate : 0);
	}, 0);
};

export const getInvoiceAmountDue = (lineItems: MinimumLineItem[]): number => {
	const totalCost = getInvoiceTotal(lineItems);
	const totalPaid = lineItems
		.filter((item) => item.type === InvoiceLineItemType.Payment)
		.reduce((total, item) => total + getLineItemCost(item), 0);

	return totalCost - totalPaid;
};

export const getLineItemCost = (lineItem: MinimumLineItem) =>
	Math.round((lineItem.quantity ?? 1) * lineItem.price);
