import {Input, type TypeInputProps} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useEffect, useState} from 'react';

/**
 * Only triggers onChange() when focus is lost.
 */
const FocusInput = (props: TypeInputProps) => {
	const [value, setValue] = useState(props.value);
	const onChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setValue(event.currentTarget.value);
		},
		[setValue],
	);

	const originalOnChange = props.onChange;
	const onBlur = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			originalOnChange?.(event, value ?? '');
		},
		[originalOnChange, value],
	);

	useEffect(() => {
		setValue(props.value);
	}, [setValue, props.value]);

	return <Input {...props} value={value} onChange={onChange} onBlur={onBlur} />;
};

export default FocusInput;
