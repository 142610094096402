import {forwardRef, useEffect, useLayoutEffect, useRef} from 'react';
import Quill from 'quill';
import {type Delta} from 'quill/core'
import deepEqual from 'deep-equal';
import styled from 'styled-components';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export type QuillContent = Delta;

interface QuillEditorProps {
	content: QuillContent | undefined,
	onChange: (content: QuillContent) => void;
}

const QuillEditor = forwardRef(
	({content, onChange}: QuillEditorProps, ref: any) => {
		const containerRef = useRef<HTMLDivElement>(null);
		const onTextChangeRef = useRef(onChange);

		useLayoutEffect(() => {
			onTextChangeRef.current = onChange;
		});

		useEffect(() => {
			if (!deepEqual(content, ref?.current?.getContents())) {
				ref?.current?.setContents(content, 'api');
			}
		}, [ref, content]);

		useEffect(() => {
			const container = containerRef.current;
			const editorContainer = container!.appendChild(
				container!.ownerDocument.createElement('div')
			);

			const quill = new Quill(editorContainer, {
				theme: 'snow',
			});

			ref.current = quill;

			quill.on(Quill.events.TEXT_CHANGE, () => {
				onTextChangeRef.current(quill.getContents());
			});

			return () => {
				ref.current = null;

				container!.innerHTML = '';
			};
		}, [ref]);

		return <QuillStylesDiv ref={containerRef} />;
	}
);

const QuillStylesDiv = styled.div`
	${({theme}) => `
		.ql-toolbar {
			border-top-left-radius: ${theme.radii.outer};
			border-top-right-radius: ${theme.radii.outer};
		}
		
		.ql-container {
			border-bottom-left-radius: ${theme.radii.outer};
			border-bottom-right-radius: ${theme.radii.outer};
		}
	`}
`;

QuillEditor.displayName = 'QuillEditor';

export default QuillEditor;