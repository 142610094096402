import {Box} from '@sproutsocial/racine';
import TransactionsTable from '../transactions/TransactionsTable';
import {ReportConfig} from '../../reports';
import {gql} from '../../__generated__';
import {useQuery} from '@apollo/client';
import {formatDate} from '../../formatters';
import {useMemo} from 'react';
import Layout from '../Layout';

const GET_TRANSACTIONS = gql(/* GraphQL */ `
	query GetTransactionsForReport(
		$startDate: String
		$endDate: String
		$categoryIds: [Int!]
		$tagIds: [Int!]
		$excludedTagIds: [Int!]
	) {
		transactions(
			startDate: $startDate
			endDate: $endDate
			categoryIds: $categoryIds
			tagIds: $tagIds
			excludedTagIds: $excludedTagIds
		) {
			id
			date
			amount
			memo
			payee
			tags {
				id
				name
				color
			}
			projects {
				id
				name
			}
			category {
				id
				name
				hidden
				priority
				categoryGroup {
					id
					name
				}
			}
		}
	}
`);

interface ReportProps {
	report: ReportConfig;
}

const Report = ({report}: ReportProps) => {
	const {data: {transactions: rawTransactions} = {}, refetch: refetchTransactions} = useQuery(GET_TRANSACTIONS, {
		variables: {
			startDate: formatDate(report.startDate),
			endDate: formatDate(report.endDate),
			categoryIds: report.categoryIds,
			tagIds: report.tagIds,
			excludedTagIds: report.excludedTagIds,
		},
	});

	const transactions = useMemo(() => {
		if (rawTransactions && report.customFilter) {
			return rawTransactions.filter(report.customFilter);
		} else {
			return rawTransactions;
		}
	}, [rawTransactions, report]);

	return (
		<Layout>
			<Layout.Header title={report.name} />
			<Layout.Body>
				<Box
					width='100%'
					border='500'
					borderRadius='outer'
					borderColor='container.border.base'
					bg='white'
				>
					<Box p='space.400'>
						{transactions && (
							<TransactionsTable
								key={report.id}
								id={`report-${report.id}`}
								transactions={transactions}
								onChange={refetchTransactions}
							/>
						)}
					</Box>
				</Box>
			</Layout.Body>
		</Layout>
	);
};

export default Report;
