import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {Box, Icon, Popout, Text, Token, Tooltip} from '@sproutsocial/racine';
import {useMutation, useQuery} from '@apollo/client';
import {gql, type TableTransaction} from '../../graphql';
import {formatCurrency, formatTransactionCategory} from '../../formatters';
import TagToken from '../tags/TagToken';
import {keyBy} from 'shared/src/map.js';
import {intersection, union} from 'shared/src/set.js';
import {formatFullDate, parseIsoDate} from 'shared/src/datetime.js';
import TagPicker from '../tags/TagPicker';
import ProjectPicker from '../forms/ProjectPicker';
import Table from '../Table';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import {List} from '../List';

interface TransactionsTableProps {
	id: string;
	transactions: TableTransaction[];
	onChange?: () => void;
}

const GET_TAGS = gql(/* GraphQL */ `
	query GetTags {
		tags {
			id
		}
	}
`);

const TAG_TRANSACTIONS = gql(/* GraphQL */ `
	mutation TagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {
		tagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)
	}
`);

const UNTAG_TRANSACTIONS = gql(/* GraphQL */ `
	mutation UntagTransactions($transactionIds: [Int!]!, $tagIds: [Int!]!) {
		untagTransactions(transactionIds: $transactionIds, tagIds: $tagIds)
	}
`);

const ADD_PROJECT_TO_TRANSACTIONS = gql(/* GraphQL */ `
	mutation AddTransactionsToProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {
		addTransactionsToProjects(transactionIds: $transactionIds, projectIds: $projectIds)
	}
`);

const REMOVE_PROJECT_FROM_TRANSACTIONS = gql(/* GraphQL */ `
	mutation RemoveTransactionsFromProjectsInTable($transactionIds: [Int!]!, $projectIds: [Int!]!) {
		removeTransactionsFromProjects(transactionIds: $transactionIds, projectIds: $projectIds)
	}
`);

const TransactionsTable = ({id: tableId, transactions, onChange}: TransactionsTableProps) => {
	const {data: {tags} = {}} = useQuery(GET_TAGS);

	const totals = useMemo<{amount: number}>(() => {
		return {
			amount:
				transactions?.reduce((total, transaction) => total + transaction.amount, 0) ?? 0,
		};
	}, [transactions]);

	const transactionsMap = useMemo(
		() => keyBy(transactions ?? [], (t) => t.id),
		[transactions],
	);

	const [selectedTransactionIds, setSelectedTransactionIds] = useState<Set<number>>(new Set());
	const onRowsSelected = useCallback((rowIds: Set<string>) => {
		setSelectedTransactionIds(
			new Set(Array.from(rowIds).map((rowId) => parseInt(rowId, 10)))
		);
	}, []);

	const selectedTransactions = useMemo(() => {
		return Array.from(selectedTransactionIds).map(
			(transactionId) => transactionsMap.get(transactionId)!,
		);
	}, [selectedTransactionIds, transactionsMap]);

	const allTransactionIds = Array.from(transactionsMap.keys());

	const someTransactionsSelected = useMemo<boolean>(() => {
		return allTransactionIds.some((id) => selectedTransactionIds.has(id));
	}, [selectedTransactionIds, allTransactionIds]);

	const [tagTransactions] = useMutation(TAG_TRANSACTIONS);
	const [untagTransactions] = useMutation(UNTAG_TRANSACTIONS);

	const [addProjectToTransactions] = useMutation(ADD_PROJECT_TO_TRANSACTIONS);
	const [removeProjectFromTransactions] = useMutation(REMOVE_PROJECT_FROM_TRANSACTIONS);

	const selectedTagIds: Set<number> = useMemo(() => {
		if (selectedTransactions.length === 0) {
			return new Set();
		}

		// Any tag from any transaction has to be "selected". indeterminateTagIds will determine a check or a dash

		return selectedTransactions.reduce((selectedTagIds, transaction) => {
			return union(
				selectedTagIds,
				transaction.tags.map((tag) => tag.id),
			);
		}, new Set<number>());
	}, [selectedTransactions]);

	const selectedProjectIds: Set<number> = useMemo(() => {
		if (selectedTransactions.length === 0) {
			return new Set();
		}

		// Any project from any transaction has to be "selected". indeterminateProjectIds will determine a check or a dash

		return selectedTransactions.reduce((selectedProjectIds, transaction) => {
			return union(
				selectedProjectIds,
				transaction.projects.map((project) => project.id),
			);
		}, new Set<number>());
	}, [selectedTransactions]);

	const indeterminateTagIds: Set<number> = useMemo(() => {
		if (selectedTransactions.length === 0) {
			return new Set();
		}

		// Take selectedTagIds and remove every tag that is on every transaction. The rest are indeterminate

		const indeterminateTagIds = new Set<number>(selectedTagIds);

		const universalTagIds = selectedTransactions.reduce(
			(allTagIds, transaction) => {
				return intersection(
					allTagIds,
					transaction.tags.map((tag) => tag.id),
				);
			},
			new Set<number>(selectedTransactions[0]?.tags.map((tag) => tag.id) ?? []),
		);

		universalTagIds.forEach((tagId) => {
			indeterminateTagIds.delete(tagId);
		});

		return indeterminateTagIds;
	}, [selectedTransactions, selectedTagIds]);

	const indeterminateProjectIds: Set<number> = useMemo(() => {
		if (selectedTransactions.length === 0) {
			return new Set();
		}

		// Take selectedProjectIds and remove every tag that is on every transaction. The rest are indeterminate

		const indeterminateProjectIds = new Set<number>(selectedProjectIds);

		const universalProjectIds = selectedTransactions.reduce(
			(allProjectIds, transaction) => {
				return intersection(
					allProjectIds,
					transaction.projects.map((project) => project.id),
				);
			},
			new Set<number>(selectedTransactions[0]?.projects.map((project) => project.id) ?? []),
		);

		universalProjectIds.forEach((tagId) => {
			indeterminateProjectIds.delete(tagId);
		});

		return indeterminateProjectIds;
	}, [selectedTransactions, selectedProjectIds]);

	const onTagSelected = useCallback(
		async (tagId: number) => {
			await tagTransactions({
				variables: {
					transactionIds: Array.from(selectedTransactionIds),
					tagIds: [tagId],
				},
			});

			onChange?.();
		},
		[onChange, tagTransactions, selectedTransactionIds],
	);

	const onTagDeselected = useCallback(
		async (tagId: number) => {
			await untagTransactions({
				variables: {
					transactionIds: Array.from(selectedTransactionIds),
					tagIds: [tagId],
				},
			});

			onChange?.();
		},
		[onChange, untagTransactions, selectedTransactionIds],
	);

	const onProjectSelected = useCallback(
		async (projectId: number) => {
			await addProjectToTransactions({
				variables: {
					transactionIds: Array.from(selectedTransactionIds),
					projectIds: [projectId],
				},
			});

			onChange?.();
		},
		[onChange, addProjectToTransactions, selectedTransactionIds],
	);

	const onProjectDeselected = useCallback(
		async (projectId: number) => {
			await removeProjectFromTransactions({
				variables: {
					transactionIds: Array.from(selectedTransactionIds),
					projectIds: [projectId],
				},
			});

			onChange?.();
		},
		[onChange, removeProjectFromTransactions, selectedTransactionIds],
	);

	const sortTransactions = useCallback(
		(a: TableTransaction, b: TableTransaction, id: string): number => {
			switch (id) {
				case 'date':
					return a.date.localeCompare(b.date);
				case 'amount':
					return a.amount - b.amount;
				default:
					return 0;
			}
		},
		[],
	);

	if (!transactions || !tags) {
		return null;
	}

	return (
		<MobileFriendlyItemDisplay
			list={
				<List id={tableId}>
					{transactions.map((transaction) => (
						<List.Item
							key={transaction.id}
							title={transaction.payee ?? transaction.memo}
							description={formatTransactionCategory(transaction.category)}
							data={formatCurrency(transaction.amount)}

						/>
					))}
				</List>
			}
			table={
				<Table
					id={tableId}
					head={[
						{id: 'date', content: 'Date', isSortable: true},
						{id: 'payee', content: 'Payee'},
						{id: 'category', content: 'Category'},
						{id: 'memo', content: 'Memo'},
						{id: 'amount', content: 'Amount', isSortable: true},
						{
							id: 'tags',
							content: someTransactionsSelected && (
								<Popout
									zIndex={999}
									content={
										<Popout.Content display='flex' flexDirection='column'>
											<TagPicker
												selectedTagIds={selectedTagIds}
												indeterminateTagIds={indeterminateTagIds}
												onTagDeselected={onTagDeselected}
												onTagSelected={onTagSelected}
											/>
										</Popout.Content>
									}
								>
									<Icon name='tag' cursor='pointer' />
								</Popout>
							),
						},
						{
							id: 'projects',
							content: someTransactionsSelected && (
								<Popout
									zIndex={999}
									content={
										<Popout.Content display='flex' flexDirection='column'>
											<ProjectPicker
												selectedProjectIds={selectedProjectIds}
												indeterminateProjectIds={indeterminateProjectIds}
												onProjectDeselected={onProjectDeselected}
												onProjectSelected={onProjectSelected}
											/>
										</Popout.Content>
									}
								>
									<Icon name='screwdriver-hammer-solid' cursor='pointer' />
								</Popout>
							),
						},
					]}
					fixedRow={{
						id: 'totals',
						cells: [
							null,
							null,
							null,
							null,
							<Text fontWeight='semibold'>
								{formatCurrency(totals.amount)}
							</Text>,
							null,
							null,
						],
					}}
					items={transactions}
					generateRow={(transaction) => {
						return {
							id: transaction.id,
							cells: [
								<Text>
									{formatFullDate(parseIsoDate(transaction.date))}
								</Text>,
								transaction.payee,
								formatTransactionCategory(transaction.category),
								transaction.memo,
								<Text color={transaction.amount > 0 ? 'green.700' : 'text.body'}>
									{formatCurrency(transaction.amount)}
								</Text>,
								transaction.tags.length > 0 && (
									<Tooltip content={transaction.tags.map((tag) => tag.name).join(', ')}>
										<Icon name='tag' />
									</Tooltip>
								),
								transaction.projects.length > 0 && (
									<Tooltip content={transaction.projects.map((project) => project.name).join(', ')}>
										<Icon name='screwdriver-hammer-solid' />
									</Tooltip>
								),
							],
							drawerContents: transaction.tags.length > 0 && (
								<Box>
									<Text as='div' fontSize='200' fontWeight='semibold' mb='space.300'>
										Tags:
									</Text>
									<Box>
										{transaction.tags.map((tag) => (
											<TagToken
												key={tag.id}
												id={tag.id}
												closeable={false}
												mr='space.300'
												mb='space.300'
											/>
										))}
									</Box>
									<Text as='div' fontSize='200' fontWeight='semibold' mb='space.300'>
										Projects:
									</Text>
									<Box>
										{transaction.projects.map((project) => (
											<Token key={project.id} mr='space.300' mb='space.300'>
												<Box display='flex' alignItems='center'>
													<Icon name='screwdriver-hammer-solid' mr='space.300' />
													{project.name}
												</Box>
											</Token>
										))}
									</Box>
								</Box>
							)
						};
					}}
					allowSelection
					onRowsSelected={onRowsSelected}
					sort={sortTransactions}
				/>
			}
		/>
	);
};

export default TransactionsTable;
