import {Outlet} from 'react-router';
import {Box} from '@sproutsocial/racine';
import MainNav from './MainNav';
import {useIsMobile} from '../util/mobile';

const AppShell = () => {
	const isMobile = useIsMobile();

	return (
		<Box
			as='main'
			display='flex'
			height='100%'
			overflow='hidden'
			flexDirection={isMobile ? 'column' : 'initial'}
		>
			<MainNav />
			<Box
				flex={1}
				overflow='auto'
			>
				<Outlet />
			</Box>
		</Box>
	);
};

export default AppShell;
