export const groupBy = <K, V, FV = V>(
	items: Iterable<V>,
	keyFunction: (item: V) => K,
	transformValue: (item: V) => FV = (item: V) => item as any,
): Map<NonNullable<K>, FV[]> => {
	const map = new Map<NonNullable<K>, FV[]>();

	for (const item of items) {
		const key = keyFunction(item);
		if (!key) {
			continue;
		}

		const existingItems = map.get(key) ?? [];

		existingItems.push(transformValue(item));

		map.set(key, existingItems);
	}

	return map;
};

export const keyBy = <K, V, FV = V>(
	items: Iterable<V>,
	keyFunction: (item: V) => K,
	transformValue: (item: V) => FV = (item: V) => item as any,
): Map<NonNullable<K>, FV> => {
	const map = new Map<NonNullable<K>, FV>();

	for (const item of items) {
		const key = keyFunction(item);

		if (key) {
			map.set(key, transformValue(item));
		}
	}

	return map;
};
