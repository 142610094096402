import {Box, Text} from '@sproutsocial/racine';
import {gql} from '../graphql';
import {useQuery} from '@apollo/client';
import {formatCurrency} from '../formatters';
import {useMemo} from 'react';

const GET_ACCOUNTS = gql(/* GraphQL */ `
	query GetDashboardAccounts {
		accounts {
			id
			name
			balance
			closed
		}
	}
`);

const GET_TRANSACTIONS = gql(/* GraphQL */ `
	query GetDashboardTransactions($startDate: String, $endDate: String) {
		transactions(startDate: $startDate, endDate: $endDate, categoryIds: [], tagIds: []) {
			date
			amount
		}
	}
`);

export default function Dashboard() {
	const {data: {accounts} = {}} = useQuery(GET_ACCOUNTS);

	const {data: {transactions: transactions2023} = {}} = useQuery(GET_TRANSACTIONS, {
		variables: {
			startDate: '2023-01-01',
			endDate: '2023-12-31',
		},
	});

	const {data: {transactions: transactions2024} = {}} = useQuery(GET_TRANSACTIONS, {
		variables: {
			startDate: '2024-01-01',
			endDate: '2024-12-31',
		},
	});

	const operatingCapital =
		accounts?.reduce((operatingCapital, account) => {
			return operatingCapital + account.balance;
		}, 0) ?? 0;

	const data2023 = useMemo(() => {
		return {
			total: transactions2023?.reduce((total, transaction) => total + transaction.amount, 0) ?? 0,
			income:
				transactions2023?.reduce((total, transaction) => {
					if (transaction.amount > 0) {
						return total + transaction.amount;
					} else {
						return total;
					}
				}, 0) ?? 0,
			expenses:
				transactions2023?.reduce((total, transaction) => {
					if (transaction.amount < 0) {
						return total + transaction.amount;
					} else {
						return total;
					}
				}, 0) ?? 0,
		};
	}, [transactions2023]);

	const data2024 = useMemo(() => {
		return {
			total: transactions2024?.reduce((total, transaction) => total + transaction.amount, 0) ?? 0,
			income:
				transactions2024?.reduce((total, transaction) => {
					if (transaction.amount > 0) {
						return total + transaction.amount;
					} else {
						return total;
					}
				}, 0) ?? 0,
			expenses:
				transactions2024?.reduce((total, transaction) => {
					if (transaction.amount < 0) {
						return total + transaction.amount;
					} else {
						return total;
					}
				}, 0) ?? 0,
		};
	}, [transactions2024]);

	return (
		<Box p='space.400'>
			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							Operating Capital
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{operatingCapital === null ? '–' : formatCurrency(operatingCapital)}
						</Text>
					</Box>
				</Box>
				{accounts?.filter((account) => !account.closed).map((account) => (
					<Box key={account.id} p='space.300' borderTop='500' borderColor='container.border.base'>
						<Box display='flex' justifyContent='space-between'>
							<Text fontSize='200' mr='space.300'>
								{account.name}
							</Text>
							<Text fontSize='200'>{formatCurrency(account.balance)}</Text>
						</Box>
					</Box>
				))}
			</Box>

			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							2024 Profit
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{formatCurrency(data2024.total)}
						</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Income
						</Text>
						<Text fontSize='200'>{formatCurrency(data2024.income)}</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Expenses
						</Text>
						<Text fontSize='200'>{formatCurrency(Math.abs(data2024.expenses))}</Text>
					</Box>
				</Box>
			</Box>

			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							2023 Profit
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{formatCurrency(data2023.total)}
						</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Income
						</Text>
						<Text fontSize='200'>{formatCurrency(data2023.income)}</Text>
					</Box>
				</Box>
				<Box p='space.300' borderTop='500' borderColor='container.border.base'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='200' mr='space.300'>
							Expenses
						</Text>
						<Text fontSize='200'>{formatCurrency(Math.abs(data2023.expenses))}</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
