import {DateTime} from 'luxon';
import {initializeReports} from './reports';

export const initializeApp = async () => {
	injectGlobalDebugValues();
	await initializeReports();
};

const injectGlobalDebugValues = () => {
	// For debugging
	(window as any).DateTime = DateTime;
};
