import {useQuery} from '@apollo/client';
import {Box, Button, Icon, Link, Table} from '@sproutsocial/racine';
import {gql} from '../../graphql';
import Layout from '../Layout';
import {type ReactNode} from 'react';

const GET_CUSTOMERS = gql(/* GraphQL */ `
	query GetCustomersList {
		customers {
			id
			name
			email
			phoneNumber
			notes
			invoices {
				id
			}
			projects {
				id
			}
		}
	}
`);

const CustomersList = () => {
	const {data: {customers} = {}} = useQuery(GET_CUSTOMERS);

	return (
		<Layout>
			<Layout.Header title='Customers'>
				<Button appearance='primary' href='/customers/new'>
					<Icon name='plus' mr='space.300' />
					New Customer
				</Button>
			</Layout.Header>
			<Layout.Body>
				<Box
					width='100%'
					p='space.300'
					border='500'
					borderRadius='outer'
					borderColor='container.border.base'
					bg='white'
				>
					<Table
						head={[
							{id: 'name', content: 'Name'},
							{id: 'email', content: 'Email'},
							{id: 'phone', content: 'Phone'},
							{id: 'notes', content: 'Notes'},
							{id: 'invoices', content: 'Invoices'},
							{id: 'project', content: 'Projects'},
						]}
						rows={
							customers?.map((customer) => {
								let invoiceContent: ReactNode = '–';
								if (customer.invoices.length === 1) {
									invoiceContent = (
										<Link href={`/invoices/${customer.invoices[0].id}/edit`}>
											1
										</Link>
									);
								} else if (customer.invoices.length > 1) {
									invoiceContent = (
										<Link href={`/customers/${customer.id}/invoices`}>
											{customer.invoices.length}
										</Link>
									);
								}

								let projectContent: ReactNode = '–';
								if (customer.projects.length === 1) {
									projectContent = (
										<Link href={`/projects/${customer.projects[0].id}`}>
											1
										</Link>
									);
								} else if (customer.projects.length > 1) {
									projectContent = (
										<Link href={`/customers/${customer.id}/projects`}>
											{customer.projects.length}
										</Link>
									);
								}

								return {
									id: customer.id,
									cells: [
										{
											id: 'name',
											content: <Link href={`/customers/${customer.id}/edit`}>{customer.name}</Link>,
										},
										{id: 'email', content: customer.email},
										{id: 'phone', content: customer.phoneNumber},
										{id: 'notes', content: customer.notes},
										{
											id: 'invoices',
											content: invoiceContent,
										},
										{
											id: 'projects',
											content: projectContent
										},
									],
								};
							}) ?? []
						}
					/>
				</Box>
			</Layout.Body>
		</Layout>
	);
};

export default CustomersList;
