import {gql} from '../../graphql';
import {useMutation, useQuery} from '@apollo/client';
import {Box, FormField, Input, LoaderButton, Select} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {orderBy} from 'shared/src/list';
import QuillEditor, {QuillContent} from '../QuillEditor';
import {useTextInputState} from '../../hooks';
import deepEqual from 'deep-equal';
import {convertDeltaToPlainText} from '../../util/quill';
import {useIsMobile} from '../../util/mobile';

interface ProjectNotesProps {
	projectId: number;
}

const GET_PROJECT = gql(/* GraphQL */ `
	query GetProjectNotes($id: Int!) {
		project(id: $id) {
			id
			notes {
				id
				name
				content
			}
		}
	}
`);

const CREATE_NOTE = gql(/* GraphQL */ `
	mutation CreateNote($note: NewNoteInput, $projectId: Int) {
		createNote(note: $note, projectId: $projectId) {
			id
		}
	}
`);

const UPDATE_NOTE = gql(/* GraphQL */ `
	mutation UpdateNote($note: UpdatedNoteInput) {
		updateNote(note: $note) {
			id
		}
	}
`);

const ProjectNotes = ({projectId}: ProjectNotesProps) => {
	const isMobile = useIsMobile();

	const {data: {project} = {}} = useQuery(GET_PROJECT, {
		variables: {
			id: projectId,
		},
	});

	const notes = useMemo(() => {
		return project ? orderBy(project.notes, 'name') : null;
	}, [project]);

	const [selectedNoteId, setSelectedNoteId] = useState<number | null>(notes?.[0]?.id ?? null);
	const onNoteChange = useCallback((event: SyntheticEvent<HTMLSelectElement>) => {
		setSelectedNoteId(parseInt(event.currentTarget.value, 10));
	}, [setSelectedNoteId]);

	const selectedNote = useMemo(() => {
		return notes?.find((note) => note.id === selectedNoteId) ?? null;
	}, [notes, selectedNoteId]);

	const [editedNoteId, setEditedNoteId] = useState<number | null>(selectedNoteId);
	const [editedName, onEditedNameChange] = useTextInputState(selectedNote?.name);
	const [editedContent, setEditedContent] = useState<QuillContent | undefined>(selectedNote?.content);

	useEffect(() => {
		if (selectedNoteId !== editedNoteId) {
			// TODO: Show warning

			setEditedNoteId(selectedNoteId);
			onEditedNameChange(null, selectedNote?.name ?? '');
			setEditedContent(selectedNote?.content ?? undefined);
		}
	}, [selectedNoteId, selectedNote, editedNoteId, setEditedNoteId, onEditedNameChange, setEditedContent]);

	const [createNote, {loading: isCreatingNote}] = useMutation(CREATE_NOTE);
	const [updateNote, {loading: isUpdatingNote}] = useMutation(UPDATE_NOTE);

	const onSaveNote = useCallback(async () => {
		if (selectedNoteId === null) {
			const {data} = await createNote({
				variables: {
					note: {
						name: editedName,
						content: editedContent,
					},
					projectId,
				},
				refetchQueries: [GET_PROJECT]
			});

			setSelectedNoteId(data!.createNote.id);
		} else {
			await updateNote({
				variables: {
					note: {
						id: selectedNoteId,
						name: editedName,
						content: editedContent,
					},
				},
				refetchQueries: [GET_PROJECT]
			});
		}
	}, [createNote, updateNote, selectedNoteId, editedName, editedContent, projectId]);

	const canSave = useMemo(() => {
		if (selectedNoteId === null) {
			return editedName && editedContent && convertDeltaToPlainText(editedContent).trim();
		} else {
			return selectedNote?.name !== editedName || (
				editedContent &&
					!deepEqual(selectedNote?.content, editedContent) &&
					convertDeltaToPlainText(editedContent).trim()
			);
		}
	}, [selectedNoteId, selectedNote, editedName, editedContent]);

	const quillRef = useRef();

	if (!project || !notes) {
		return null;
	}

	return (
		<Box px={isMobile ? 'space.300' : 0}>
			<Box display='flex' justifyContent='space-between' mb='space.400'>
				<Select
					id='noteId'
					name='noteId'
					value={selectedNoteId ?? 'new'}
					onChange={onNoteChange}
				>
					{notes.map((note) => (
						<option key={note.id} value={note.id}>
							{note.name}
						</option>
					))}
					<option value='new'>New Note</option>
				</Select>

				<LoaderButton
					appearance='primary'
					ml='space.300'
					minWidth={80}
					onClick={onSaveNote}
					disabled={!canSave}
					isLoading={isCreatingNote || isUpdatingNote}
				>
					{selectedNoteId ? 'Save' : 'Create'}
				</LoaderButton>
			</Box>

			<FormField label='Name'>
				{(props) => (
					<Input
						{...props}
						name='noteName'
						value={editedName}
						onChange={onEditedNameChange}
						mb='space.400'
					/>
				)}
			</FormField>

			<Box bg='white' borderRadius='outer'>
				<QuillEditor
					ref={quillRef}
					content={editedContent}
					onChange={setEditedContent}
				/>
			</Box>
		</Box>
	);
};

export default ProjectNotes