import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, LoaderButton} from '@sproutsocial/racine';
import InvoiceEditForm from './InvoiceEditForm';
import {gql} from '../../graphql';
import {useMutation, useQuery} from '@apollo/client';
import {Invoice, InvoiceLineItem} from '../../models';
import Layout from '../Layout';

interface InvoiceEditPageProps {
	invoiceId: number | null;
}

const GET_INVOICE = gql(/* GraphQL */ `
	query GetInvoice($id: Int!) {
		invoice(id: $id) {
			id
			date
			description
			customer {
				id
			}
			items {
				id
				type
				name
				description
				quantity
				price
				taxed
			}
		}
	}
`);

const SAVE_INVOICE = gql(/* GraphQL */ `
	mutation SaveInvoice($invoice: InvoiceInput!) {
		saveInvoice(invoice: $invoice)
	}
`);

const InvoiceEditPage = ({invoiceId}: InvoiceEditPageProps) => {
	const {data: {invoice} = {}} = useQuery(GET_INVOICE, {
		skip: !invoiceId,
		variables: {
			id: invoiceId!,
		},
	});

	const [saveInvoice, {loading: isSaving}] = useMutation(SAVE_INVOICE);

	const [editedInvoice, setEditedInvoice] = useState<Invoice | null>(
		invoice ? new Invoice(invoice as any) : invoiceId ? null : new Invoice(),
	);

	useEffect(() => {
		if (invoiceId && invoice?.id && !editedInvoice?.id) {
			setEditedInvoice(new Invoice(invoice as any));
		}
	}, [invoiceId, invoice, editedInvoice, setEditedInvoice]);

	const invoiceChanged = useMemo<boolean>(() => {
		if (!invoice) {
			return true;
		}

		// This only happens before the invoice is loaded
		if (!editedInvoice) {
			return false;
		}

		return !editedInvoice.equals(new Invoice(invoice as any));
	}, [invoice, editedInvoice]);

	const onInvoiceUpdated = useCallback(
		(updatedInvoice: Invoice) => {
			if (
				updatedInvoice.items.length === 0 ||
				!updatedInvoice.items[updatedInvoice.items.length - 1].isEmpty()
			) {
				updatedInvoice.items.push(new InvoiceLineItem());
			}

			setEditedInvoice(updatedInvoice);
		},
		[setEditedInvoice],
	);

	const onSave = useCallback(async () => {
		if (!editedInvoice) {
			return;
		}

		const {data} = await saveInvoice({
			variables: {
				invoice: editedInvoice.toGraphInput(),
			},
		});

		window.location.href = `/invoices/${data!.saveInvoice}/edit`;
	}, [editedInvoice, saveInvoice]);

	const isSaveEnabled = editedInvoice && invoiceChanged && editedInvoice.isValid();

	if (!editedInvoice) {
		return null;
	}

	return (
		<Layout>
			<Layout.Header title={invoiceId ? 'Edit Invoice' : 'Create Invoice'}>
				<form
					method='POST'
					action={`/api/invoices/pdf/${editedInvoice.id}`}
					style={{display: 'inline'}}
				>
					<Button
						appearance='secondary'
						mr='space.300'
						disabled={invoiceChanged}
						// @ts-ignore
						type='submit'
					>
						Download PDF
					</Button>
				</form>
				<Button
					appearance='secondary'
					mr='space.300'
					disabled={invoiceChanged}
					href={`/preview/${editedInvoice.id}`}
					external
				>
					Preview
				</Button>
				<LoaderButton
					appearance='primary'
					width='100px'
					disabled={!isSaveEnabled}
					isLoading={isSaving}
					onClick={onSave}
				>
					Save
				</LoaderButton>
			</Layout.Header>
			<Layout.Body>
				<InvoiceEditForm invoice={editedInvoice} onInvoiceUpdated={onInvoiceUpdated} />
			</Layout.Body>
		</Layout>
	);
};

export default InvoiceEditPage;
