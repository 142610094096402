import {DateTime} from 'luxon';

export const parseDatabaseDate = (date: Date): DateTime => {
	return DateTime.fromJSDate(date);
};

export const formatDatabaseDate = (date: DateTime): Date => {
	return date.toJSDate();
};

export const parseIsoDate = (date: string): DateTime => {
	return DateTime.fromFormat(date, 'yyyy-MM-dd');
};

export const formatIsoDate = (dateTime: DateTime): string => {
	return dateTime.toFormat('yyyy-MM-dd');
};

export const formatFullDate = (dateTime: DateTime): string => {
	return dateTime.toLocaleString(DateTime.DATE_FULL);
};
