import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import InvoiceTable from '../invoices/InvoiceTable';
import {Loader} from '@sproutsocial/racine';
import Layout from '../Layout';

const GET_CUSTOMER_INVOICES = gql(/* GraphQL */ `
	query GetCustomerInvoices($customerId: String!) {
		customer(id: $customerId) {
			name
			invoices {
				id
				date
				items {
					type
					quantity
					price
					taxed
				}
			}
		}
	}
`);

interface CustomerInvoiceListProps {
	customerId: string;
}

const CustomerInvoiceList = ({customerId}: CustomerInvoiceListProps) => {
	const {data: {customer} = {}} = useQuery(GET_CUSTOMER_INVOICES, {
		variables: {
			customerId,
		},
	});

	if (!customer) {
		return <Loader />;
	}

	return (
		<Layout>
			<Layout.Header title={`Invoices for ${customer.name}`} />
			<Layout.Body>
				<InvoiceTable invoices={customer.invoices} showCustomerName={false} />
			</Layout.Body>
		</Layout>
	);
};

export default CustomerInvoiceList;
