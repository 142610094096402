import {Box, Token} from '@sproutsocial/racine';
import {type TypeTokenProps} from '@sproutsocial/racine/dist/types/Token/TokenTypes';
import {SyntheticEvent, useCallback} from 'react';
import TagName from './TagName';

interface TagTokenProps extends Omit<TypeTokenProps, 'id' | 'children' | 'onClick'> {
	id: number;
	onClick?: (event: SyntheticEvent<HTMLButtonElement>, tagId: number) => void;
}

const TagToken = ({id, onClick, ...rest}: TagTokenProps) => {
	const handleClick = useCallback(
		(event: SyntheticEvent<HTMLButtonElement>) => {
			onClick?.(event, id);
		},
		[id, onClick],
	);

	return (
		<Token onClick={onClick ? handleClick : undefined} {...rest}>
			<Box display='flex' alignItems='center'>
				<TagName id={id} showIcon />
			</Box>
		</Token>
	);
};

export default TagToken;
