import Layout from '../Layout';
import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import {Tabs} from '@sproutsocial/racine';
import React, {useCallback} from 'react';
import ProjectOverview from './ProjectOverview';
import ProjectTransactions from './ProjectTransactions';
import ProjectFiles from './ProjectFiles';
import {useNavigate} from 'react-router';
import ProjectNotes from './ProjectNotes';

const GET_PROJECT = gql(/* GraphQL */ `
	query GetProjectMetadata($id: Int!) {
		project(id: $id) {
			id
			name
			transactions {
				id
				date
				amount
				memo
				payee
				paymentAccount {
					id
					name
				}
				tags {
					id
					name
					color
				}
				projects {
					id
					name
				}
				category {
					id
					name
					hidden
					priority
					categoryGroup {
						id
						name
					}
				}
			}
		}
	}
`);

export type ViewProjectTab = 'overview' | 'transactions' | 'notes' | 'files';
export const ViewProjectTabs: ViewProjectTab[] = ['overview', 'transactions', 'notes', 'files'];

interface ViewProjectProps {
	projectId: number;
	selectedTab: ViewProjectTab;
}

const ViewProject = ({projectId, selectedTab}: ViewProjectProps) => {
	const {data: {project} = {}} = useQuery(GET_PROJECT, {
		variables: {
			id: projectId,
		},
	});

	const navigate = useNavigate();
	const onTabSelected = useCallback((newTab: ViewProjectTab) => {
		navigate(`/projects/${projectId}/${newTab}`);
	}, [navigate, projectId]);

	if (!project) {
		return null;
	}

	return (
		<Layout>
			<Layout.Header title={project.name} />
			<Layout.Body>
				<Tabs
					onSelect={onTabSelected as (() => string)}
					selectedId={selectedTab}
					fullWidth
					mb='space.400'
				>
					<Tabs.Button id='overview'>
						Overview
					</Tabs.Button>
					<Tabs.Button id='transactions'>
						Transactions
					</Tabs.Button>
					<Tabs.Button id='notes'>
						Notes
					</Tabs.Button>
					<Tabs.Button id='files'>
						Files
					</Tabs.Button>
				</Tabs>

				{selectedTab === 'overview' && <ProjectOverview projectId={projectId} />}
				{selectedTab === 'transactions' && <ProjectTransactions projectId={projectId} />}
				{selectedTab === 'notes' && <ProjectNotes projectId={projectId} />}
				{selectedTab === 'files' && <ProjectFiles projectId={projectId} />}
			</Layout.Body>
		</Layout>
	);
};

export default ViewProject;
