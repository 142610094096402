import {Box, Text} from '@sproutsocial/racine';

const InvoiceLineItemsHeaderPreview = () => {
	return (
		<Box display='flex' my='space.200'>
			<Box flex={5}>
				<Text fontSize='200' fontWeight='semibold'>
					ITEM
				</Text>
			</Box>
			<Box flex={1}>
				<Text fontSize='200' fontWeight='semibold'>
					QTY
				</Text>
			</Box>
			<Box flex={1}>
				<Text fontSize='200' fontWeight='semibold'>
					RATE
				</Text>
			</Box>
			<Box flex={1}>
				<Text fontSize='200' fontWeight='semibold'>
					AMOUNT
				</Text>
			</Box>
		</Box>
	);
};

export default InvoiceLineItemsHeaderPreview;
