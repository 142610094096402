import {useLayoutEffect, useState} from 'react';

export const useIsMobile = (): boolean => {
	const [mobile, setMobile] = useState<boolean>(isMobile());

	useLayoutEffect(() => {
		const callback = () => {
			setMobile(isMobile());
		};

		window.addEventListener('resize', callback);

		return () => {
			window.removeEventListener('resize', callback);
		};
	});

	return mobile;
};

export const isMobile = () => {
	return window.document.body.getBoundingClientRect().width < 600;
};