import {Box, Text} from '@sproutsocial/racine';
import InvoiceLineItemEditForm from './InvoiceLineItemEditForm';
import {InvoiceLineItem} from '../../models';
import {formatCurrency} from '../../formatters';
import {getInvoiceTotal} from '../../invoice-helpers';

interface InvoiceLineItemsEditFormProps {
	items: Array<InvoiceLineItem>;
	onChange: (updatedLineItem: InvoiceLineItem) => void;
}

const InvoiceLineItemsEditForm = ({items, onChange}: InvoiceLineItemsEditFormProps) => {
	return (
		<>
			<Text as='h2' fontWeight='300' color='text.headline' mb='space.300'>
				Line Items
			</Text>

			<Box>
				<Box display='flex'>
					<Box flex={3} mr='space.300'>
						<Text fontSize='200' fontWeight='semibold'>
							Name
						</Text>
					</Box>
					<Box flex={3} mr='space.300'>
						<Text fontSize='200' fontWeight='semibold'>
							Description
						</Text>
					</Box>
					<Box flex={1} mr='space.300'>
						<Text fontSize='200' fontWeight='semibold'>
							Quantity
						</Text>
					</Box>
					<Box flex={1} mr='space.300'>
						<Text fontSize='200' fontWeight='semibold'>
							Cost
						</Text>
					</Box>
					<Box flex={1} mr='space.300'>
						<Text fontSize='200' fontWeight='semibold'>
							Type
						</Text>
					</Box>
					<Box flex={1}>
						<Text fontSize='200' fontWeight='semibold'>
							Taxed
						</Text>
					</Box>
				</Box>

				{items.map((item) => (
					<InvoiceLineItemEditForm key={item.id} item={item} onChange={onChange} />
				))}

				<Box display='flex'>
					<Box flex={3} mr='space.300' />
					<Box flex={3} mr='space.300' />
					<Box flex={1} mr='space.300' />
					<Box flex={1} mr='space.300' pr='space.200'>
						<Text as='div' fontSize='200' fontWeight='semibold' textAlign='right'>
							{formatCurrency(getInvoiceTotal(items))}
						</Text>
					</Box>
					<Box flex={1} mr='space.300' />
					<Box flex={1}/>
				</Box>
			</Box>
		</>
	);
};

export default InvoiceLineItemsEditForm;
