import {type ReactNode} from 'react';
import {Box, Text} from '@sproutsocial/racine';
import {useIsMobile} from '../util/mobile';

interface LayoutProps {
	children: ReactNode;
}

const Layout = ({children}: LayoutProps) => {
	return (
		<Box display='flex' flexDirection='column'>
			{children}
		</Box>
	);
};

interface LayoutHeaderProps {
	title?: string;
	children?: ReactNode;
	[prop: string]: any;
}

const Header = ({title, children, ...rest}: LayoutHeaderProps) => {
	return (
		<Box
			display='flex'
			justifyContent={title ? 'space-between' : 'flex-end'}
			alignItems='center'
			borderBottom='500'
			borderColor='container.border.base'
			p='space.400'
			{...rest}
		>
			{title && (
				<Text as='h2' fontSize='400' fontWeight='semibold' color='text.headline'>
					{title}
				</Text>
			)}

			<Box>
				{children}
			</Box>
		</Box>
	);
};

interface LayoutBodyProps {
	children: ReactNode;
	[prop: string]: any;
}

const Body = ({children, ...rest}: LayoutBodyProps) => {
	const isMobile = useIsMobile();

	return (
		<Box p={isMobile ? 0 : 'space.400'} {...rest}>
			{children}
		</Box>
	);
};

Layout.Header = Header;
Layout.Body = Body;

export default Layout;
