import {Box, FormField, Icon, Input, LoaderButton} from '@sproutsocial/racine';
import {useDateInputState, useTextInputState} from '../../hooks';
import DatePicker from '../DatePicker';
import {useCallback, useMemo, useState} from 'react';
import {gql, type NewProjectInput} from '../../graphql';
import {formatDate} from '../../formatters';
import {useMutation} from '@apollo/client';
import CustomerPicker from '../CustomerPicker';
import {DateTime} from 'luxon';
import Layout from '../Layout';

const CREATE_PROJECT = gql(/* GraphQL */ `
	mutation CreateProject($newProject: NewProjectInput) {
		createProject(project: $newProject) {
			id
		}
	}
`);

const NewProject = () => {
	const [name, onNameChange] = useTextInputState();
	const [customerId, onCustomerIdChange] = useState<string | null>(null);
	const [description, onDescriptionChange] = useTextInputState();
	const [startDate, onStartDateChange] = useDateInputState();
	const [cost, onCostChange] = useTextInputState();
	const [deposit, onDepositChange] = useTextInputState();

	const isValid = useMemo<boolean>(() => {
		if (!name.trim() || !description.trim()) {
			return false;
		}

		if (!customerId) {
			return false;
		}

		if (startDate < DateTime.fromISO('2020-01-01')) {
			return false;
		}

		return true;
	}, [name, description, customerId, startDate]);

	const [createProject, {loading: isCreating}] = useMutation(CREATE_PROJECT);

	const onCreate = useCallback(async () => {
		const newProject: NewProjectInput = {
			name: name.trim(),
			description: description.trim(),
			startDate: formatDate(startDate),
			cost: cost ? Math.floor(parseFloat(cost) * 100) : null,
			deposit: deposit ? Math.floor(parseFloat(deposit) * 100) : null,
			customerId: customerId!,
		};

		const {data} = await createProject({
			variables: {
				newProject,
			},
		});

		window.location.href = `/projects/${data?.createProject.id}`;
	}, [createProject, name, description, startDate, customerId, cost, deposit]);

	return (
		<Layout>
			<Layout.Header title='New Project'>
				<LoaderButton
					appearance='primary'
					isLoading={isCreating}
					onClick={onCreate}
					disabled={!isValid}
				>
					<Icon name='plus' mr='space.300' /> Create
				</LoaderButton>
			</Layout.Header>

			<Layout.Body>
				<Box maxWidth={300}>
					<FormField label='Name'>
						{(props) => (
							<Input {...props} name='projectName' value={name} onChange={onNameChange} required />
						)}
					</FormField>
					<FormField label='Customer'>
						{(props) => (
							<CustomerPicker
								{...props}
								selectedCustomerId={customerId}
								onChange={onCustomerIdChange}
							/>
						)}
					</FormField>
				</Box>
				<Box maxWidth={600}>
					<FormField label='Description'>
						{(props) => (
							<Input
								{...props}
								name='description'
								value={description}
								onChange={onDescriptionChange}
								required
							/>
						)}
					</FormField>
				</Box>
				<Box maxWidth={300}>
					<FormField label='Start Date'>
						{(props) => <DatePicker {...props} value={startDate} onChange={onStartDateChange} />}
					</FormField>
					<FormField label='Cost'>
						{(props) => (
							<Input
								{...props}
								name='cost'
								type='number'
								value={cost}
								onChange={onCostChange}
								required
								elemBefore='$'
								inputProps={{
									min: 0,
								}}
							/>
						)}
					</FormField>
					<FormField label='Deposit'>
						{(props) => (
							<Input
								{...props}
								name='deposit'
								type='number'
								value={deposit}
								onChange={onDepositChange}
								required
								elemBefore='$'
								inputProps={{
									min: 0,
								}}
							/>
						)}
					</FormField>
				</Box>
			</Layout.Body>
		</Layout>
	);
};

export default NewProject;
