import {type Project, type Transaction} from '../../graphql';
import MobileFriendlyItemDisplay from '../MobileFriendlyItemDisplay';
import {List} from '../List';
import {formatCurrency} from '../../formatters';
import {calculateProjectProfit} from '../../helpers/project';
import Table from '../Table';
import {Link} from '@sproutsocial/racine';

type MinimumProject = Pick<Project, 'id' | 'name' | 'description' | 'startDate'> & {
	customer?: Pick<Project['customer'], 'id' | 'name'>,
	transactions?: Array<Pick<Transaction, 'amount'>>,
};

interface ProjectsTableProps {
	projects: MinimumProject[];
	showCustomerName?: boolean;
	showProfit?: boolean;
}

const ProjectsTable = ({projects, showCustomerName = true, showProfit = false}: ProjectsTableProps) => {
	return (
		<MobileFriendlyItemDisplay
			list={
				<List id='projects'>
					{projects.map((project) => (
						<List.Item
							key={project.id}
							title={project.name}
							description={project.description}
							data={
								formatCurrency(
									calculateProjectProfit({
										...project,
										transactions: project.transactions ?? []
									})
								)
							}
						/>
					))}
				</List>
			}
			table={
				<Table
					id='projects'
					head={[
						{id: 'name', content: 'Name'},
						{id: 'description', content: 'Description'},
						{id: 'startDate', content: 'Start Date'},
						...(showCustomerName ? [{id: 'customer', content: 'Customer'}] : []),
						...(showProfit ? [{id: 'profit', content: 'Profit'}] : [])
					]}
					items={projects}
					generateRow={(project) => ({
						id: String(project.id),
						cells: [
							<Link href={`/projects/${project.id}`}>{project.name}</Link>,
							project.description,
							project.startDate,
							...(showCustomerName ? [project.customer?.name] : []),
							...(showProfit ?
								[formatCurrency(
									calculateProjectProfit({
										...project,
										transactions: project.transactions ?? []
									})
								)] :
								[])
						],
					})}
				/>
			}
		/>
	);
};

export default ProjectsTable;
