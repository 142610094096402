import {SyntheticEvent, useCallback} from 'react';
import {useQuery} from '@apollo/client';
import {Select} from '@sproutsocial/racine';
import {gql} from '../__generated__';
import {orderBy} from 'shared/src/list.js';

const GET_CUSTOMERS = gql(/* GraphQL */ `
	query GetCustomersForCustomerPicker {
		customers {
			id
			name
		}
	}
`);

interface CustomerPickerProps {
	id: string;
	selectedCustomerId: string | null;
	onChange: (selectedCustomerId: string) => void;
	disabled?: boolean;
}

const CustomerPicker = ({id, selectedCustomerId, onChange, disabled}: CustomerPickerProps) => {
	const {data: {customers} = {}} = useQuery(GET_CUSTOMERS);
	const onCustomerSelected = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			const newId = event.currentTarget.value;

			// A safety measure in case they're able to select the null option somehow
			if (newId) {
				onChange(newId);
			}
		},
		[onChange],
	);

	const sortedCustomers = orderBy(customers ?? [], 'name');

	return (
		<Select
			id={id}
			name='customerId'
			value={selectedCustomerId ? selectedCustomerId + '' : ''}
			onChange={onCustomerSelected}
			disabled={disabled}
		>
			{selectedCustomerId === null && <option value=''>Select a Customer</option>}
			{sortedCustomers.map(({id, name}) => (
				<option key={id} value={id + ''}>
					{name}
				</option>
			))}
		</Select>
	);
};

export default CustomerPicker;
