import {Box, Text} from '@sproutsocial/racine';
import {formatCurrency} from '../../formatters';
import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import {
	getInvoiceAmountDue,
	getInvoiceSubtotal,
	getInvoiceTaxes,
	getInvoiceTotal,
} from '../../invoice-helpers';

interface InvoiceFooterPreviewProps {
	invoiceId: number;
}

const GET_INVOICE_FOOTER = gql(/* GraphQL */ `
	query GetInvoiceFooter($id: Int!) {
		invoice(id: $id) {
			items {
				type
				quantity
				price
				taxed
			}
		}
	}
`);

const InvoiceFooterPreview = ({invoiceId}: InvoiceFooterPreviewProps) => {
	const {data: {invoice} = {}} = useQuery(GET_INVOICE_FOOTER, {
		variables: {
			id: invoiceId,
		},
	});

	if (!invoice) {
		return null;
	}

	const subtotal = getInvoiceSubtotal(invoice.items);
	const taxes = getInvoiceTaxes(invoice.items);
	const total = getInvoiceTotal(invoice.items);
	const amountDue = getInvoiceAmountDue(invoice.items);

	return (
		<Box display='flex' flexDirection='column' width='100%'>
			<Box display='flex' justifyContent='space-between'>
				<Box flex={5}>
					<Text fontSize='200'>Subtotal</Text>
				</Box>
				<Box flex={1} />
				<Box flex={1} />
				<Box flex={1}>
					<Text fontSize='200'>{formatCurrency(subtotal)}</Text>
				</Box>
			</Box>
			<Box display='flex' justifyContent='space-between'>
				<Box flex={5}>
					<Text fontSize='200'>Taxes</Text>
				</Box>
				<Box flex={1} />
				<Box flex={1} />
				<Box flex={1}>
					<Text fontSize='200'>{formatCurrency(taxes)}</Text>
				</Box>
			</Box>
			<Box display='flex' justifyContent='space-between'>
				<Box flex={5}>
					<Text fontSize='200' fontWeight='semibold'>
						Total
					</Text>
				</Box>
				<Box flex={1} />
				<Box flex={1} />
				<Box flex={1}>
					<Text fontSize='200' fontWeight='semibold'>
						{formatCurrency(total)}
					</Text>
				</Box>
			</Box>

			<Box width='100%' height={1} bg='black' my='space.400' />

			<Box display='flex' justifyContent='space-between'>
				<Box flex={5}>
					<Text
						fontSize='200'
						fontWeight='semibold'
						color={amountDue > 0 ? 'red.800' : 'green.800'}
					>
						Amount Due
					</Text>
				</Box>
				<Box flex={1} />
				<Box flex={1} />
				<Box flex={1}>
					<Text
						fontSize='200'
						fontWeight='semibold'
						color={amountDue > 0 ? 'red.800' : 'green.800'}
					>
						{amountDue > 0 ? formatCurrency(amountDue) : 'PAID'}
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default InvoiceFooterPreview;
