import {Select} from '@sproutsocial/racine';
import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import {SyntheticEvent, useCallback} from 'react';

const GET_INVOICES = gql(/* GraphQL */ `
	query GetAllInvoices {
		invoices {
			id
			description
		}
	}
`);

interface InvoicePickerProps {
	id: string;
	selectedInvoiceId: number | null;
	onChange: (selectedInvoiceId: number) => void;
}

const InvoicePicker = ({id, selectedInvoiceId, onChange}: InvoicePickerProps) => {
	const {data: {invoices} = {}} = useQuery(GET_INVOICES);

	const onInvoiceSelected = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			const {value} = event.currentTarget;

			if (value) {
				onChange(parseInt(value, 10));
			}
		},
		[onChange],
	);

	return (
		<Select id={id} name='invoiceId' value={selectedInvoiceId ?? ''} onChange={onInvoiceSelected}>
			{selectedInvoiceId === null && <option value=''>Select an Invoice</option>}
			{invoices?.map((invoice) => (
				<option key={invoice.id} value={invoice.id.toString()}>
					{invoice.id.toString().padStart(4, '0')} - {invoice.description}
				</option>
			))}
		</Select>
	);
};

export default InvoicePicker;
