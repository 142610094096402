import {memo, type ReactElement} from 'react';
import {List} from './List';
import Table from './Table';
import {useIsMobile} from '../util/mobile';

interface MobileFriendlyItemDisplayProps {
	list: ReactElement<typeof List>;
	table: ReactElement<typeof Table>;
}

const MobileFriendlyItemDisplay = memo(({list, table}: MobileFriendlyItemDisplayProps) => {
	const isMobile = useIsMobile();

	return isMobile ? list : table;
});

export default MobileFriendlyItemDisplay;