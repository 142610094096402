import {Box, FormField, Input, LoaderButton} from '@sproutsocial/racine';
import {useCallback, useState, type KeyboardEvent, type SyntheticEvent} from 'react';

const Login = () => {
	const [password, setPassword] = useState<string>('');
	const onPasswordChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			setPassword(event.currentTarget.value);
		},
		[setPassword],
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [didError, setDidError] = useState<boolean>(false);
	const onLogin = useCallback(async () => {
		setIsLoading(true);

		const response = await fetch('/api/sessions', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({password}),
		});

		if (response.ok) {
			window.location.href = '/';
		} else {
			setIsLoading(false);
			setDidError(true);
		}
	}, [setIsLoading, password]);

	const onKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>, value: string) => {
			if (event.key === 'Enter') {
				onLogin();
			}
		},
		[onLogin],
	);

	return (
		<Box width='100%' display='flex' justifyContent='center'>
			<Box mt='10%'>
				<FormField label='Password'>
					{(props) => (
						<Input
							{...props}
							id='password'
							name='password'
							type='password'
							value={password}
							isInvalid={didError}
							disabled={isLoading}
							onChange={onPasswordChange}
							onKeyDown={onKeyDown}
						/>
					)}
				</FormField>
				<LoaderButton appearance='primary' isLoading={isLoading} onClick={onLogin}>
					Login
				</LoaderButton>
			</Box>
		</Box>
	);
};

export default Login;
