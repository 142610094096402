import {Icon} from '@sproutsocial/racine';
import {gql, Tag} from '../../graphql';
import {useMemo} from 'react';
import {useQuery} from '@apollo/client';

const GET_TAG = gql(/* GraphQL */ `
	query GetTag($id: Int!) {
		tag(id: $id) {
			id
			name
			color
			parentTag {
				id
				name
				color
				parentTag {
					id
					name
					color
					parentTag {
						id
						name
						color
						parentTag {
							id
							name
							color
						}
					}
				}
			}
		}
	}
`);

interface TagNameProps {
	id: number;
	showIcon?: boolean;
}

const TagName = ({id, showIcon = false}: TagNameProps) => {
	const {data: {tag} = {}} = useQuery(GET_TAG, {
		variables: {id},
	});

	const fullTagName = useMemo(() => {
		if (!tag) {
			return '';
		}

		const getTagName = (tag: Tag): string => {
			const parentTagName = tag.parentTag ? getTagName(tag.parentTag) : null;

			return parentTagName ? `${parentTagName} > ${tag.name}` : tag.name;
		};

		return getTagName(tag);
	}, [tag]);

	return (
		<>
			{showIcon && tag && <Icon name='tag' color={tag.color} mr='space.300' />}
			{fullTagName}
		</>
	);
};

export default TagName;
