import {DateTime} from 'luxon';
import deepEqual from 'deep-equal';
import {
	type Invoice as GraphInvoice,
	type InvoiceInput as GraphInvoiceInput,
	type InvoiceLineItem as GraphInvoiceLineItem,
	type InvoiceLineItemInput as GraphInvoiceLineItemInput,
	InvoiceLineItemType,
} from './graphql';
import {getInvoiceSubtotal, getInvoiceTaxes} from './invoice-helpers';

/** @deprecated */
export class Invoice {
	public id: number | null;
	public date: DateTime;
	public description: string;
	public customerId: string | null;
	public items: Array<InvoiceLineItem>;

	constructor();
	constructor(invoice: Invoice);
	constructor(initializer?: GraphInvoice | Invoice) {
		if (initializer) {
			if (initializer instanceof Invoice) {
				this.id = initializer.id;
				this.date = initializer.date;
				this.description = initializer.description;
				this.customerId = initializer.customerId;
				this.items = initializer.items.map((item) => item.clone());
			} else {
				this.id = initializer.id ?? null;
				this.date = DateTime.fromISO(initializer.date);
				this.description = initializer.description;
				this.customerId = initializer.customer.id;
				this.items = initializer.items.map((itemDto) => new InvoiceLineItem(itemDto));
			}
		} else {
			this.id = null;
			this.date = DateTime.now();
			this.description = '';
			this.customerId = null;
			this.items = [new InvoiceLineItem()];
		}
	}

	public isValid(testCustomerId: boolean = true): boolean {
		if (!this.date.isValid || !this.description.trim()) {
			return false;
		}

		if (testCustomerId && !this.customerId) {
			return false;
		}

		if (this.items.every((item) => item.isEmpty())) {
			return false;
		}

		if (this.items.some((item) => !item.isEmpty() && !item.isValid())) {
			return false;
		}

		return true;
	}

	public getSubtotal(): number {
		return getInvoiceSubtotal(this.items);
	}

	public getTaxes(): number {
		return getInvoiceTaxes(this.items);
	}

	public toGraphInput(): GraphInvoiceInput {
		return {
			id: this.id,
			date: this.date.toFormat('yyyy-LL-dd'),
			description: this.description.trim(),
			customer: {
				id: this.customerId!,
			},
			items: this.items.filter((item) => !item.isEmpty()).map((item) => item.toGraphInput()),
		};
	}

	public clone(): Invoice {
		return new Invoice(this);
	}

	public equals(other: Invoice): boolean {
		return deepEqual(this.toGraphInput(), other.toGraphInput());
	}
}

/** @deprecated */
export class InvoiceLineItem {
	public readonly id: string;
	public type: InvoiceLineItemType;
	public name: string;
	public description?: string;
	public quantity?: number;
	public price: number;
	public taxed: boolean = false;

	constructor();
	constructor(item: InvoiceLineItem);
	constructor(dto: GraphInvoiceLineItem);
	constructor(initializer?: InvoiceLineItem | GraphInvoiceLineItem) {
		if (initializer) {
			if (initializer instanceof InvoiceLineItem) {
				this.id = initializer.id;
				this.type = initializer.type;
				this.name = initializer.name;
				this.description = initializer.description;
				this.quantity = initializer.quantity;
				this.price = initializer.price;
				this.taxed = initializer.taxed;
			} else {
				this.id = initializer.id;
				this.type = initializer.type;
				this.name = initializer.name;
				this.description = initializer.description ?? undefined;
				this.quantity = initializer.quantity ?? undefined;
				this.price = initializer.price;
				this.taxed = Boolean(initializer.taxed);
			}
		} else {
			this.id = crypto.randomUUID();
			this.type = InvoiceLineItemType.Cost;
			this.name = '';
			this.price = 0;
		}
	}

	public isEmpty(): boolean {
		return !this.name.trim();
	}

	public isValid(): boolean {
		if (!this.id.trim() || !this.name.trim() || this.price <= 0) {
			return false;
		}

		if (typeof this.quantity === 'number' && this.quantity <= 0) {
			return false;
		}

		return true;
	}

	public toGraphInput(): GraphInvoiceLineItemInput {
		return {
			id: this.id,
			type: this.type,
			name: this.name.trim(),
			description: this.description ? this.description.trim() : undefined,
			quantity: this.quantity || undefined,
			price: this.price,
			taxed: Boolean(this.taxed),
		};
	}

	public clone(): InvoiceLineItem {
		return new InvoiceLineItem(this);
	}
}
