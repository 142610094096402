export const debounce = <F extends Function>(fun: F, delay: number): F => {
	let timerId: number | undefined;

	const debounced = (...args: any[]) => {
		window.clearTimeout(timerId);

		timerId = window.setTimeout(() => {
			fun(...args);
		}, delay);
	};

	return debounced as any;
};

export const once = <F extends Function>(fun: F): F => {
	let called = false;
	let returnValue: any = undefined;

	const wrapper = (...args: any[]) => {
		if (!called) {
			called = true;

			returnValue = fun(...args);
		}

		return returnValue;
	};

	return wrapper as any;
};