import {FormField, Input, LoaderButton, Textarea} from '@sproutsocial/racine';
import {useMutation, useQuery} from '@apollo/client';
import {gql} from '../../graphql';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTextInputState} from '../../hooks';
import Layout from '../Layout';

interface CustomerEditProps {
	customerId: string | null;
}

const GET_CUSTOMER = gql(/* GraphQL */ `
	query GetCustomer($customerId: String!) {
		customer(id: $customerId) {
			id
			name
			email
			phoneNumber
			notes
		}
	}
`);

const CREATE_CUSTOMER = gql(/* GraphQL */ `
	mutation CreateCustomer($customer: NewCustomerInput!) {
		createCustomer(customer: $customer)
	}
`);

const UPDATE_CUSTOMER = gql(/* GraphQL */ `
	mutation UpdateCustomer($customerId: String!, $customer: NewCustomerInput!) {
		updateCustomer(customerId: $customerId, customer: $customer)
	}
`);

const CustomerEdit = ({customerId}: CustomerEditProps) => {
	const queryResult = useQuery(GET_CUSTOMER, {
		variables: {
			customerId: customerId!,
		},
		skip: !customerId,
	});

	const customer = queryResult?.data?.customer;

	const [name, onNameChange] = useTextInputState(customer?.name ?? '');
	const [email, onEmailChange] = useTextInputState(customer?.email ?? '');
	const [phoneNumber, onPhoneNumberChange] = useTextInputState(customer?.phoneNumber ?? '');
	const [notes, onNotesChange] = useTextInputState(customer?.notes ?? '');
	const saveDisabled = useMemo(() => {
		// Check required fields
		if (!name) {
			return true;
		}

		if (customer) {
			// If all fields are the same, disable save
			if (
				customer.name === name.trim() &&
				customer.email === email.trim() &&
				(customer.phoneNumber ?? '') === phoneNumber.trim() &&
				(customer.notes ?? '') === notes.trim()
			) {
				return true;
			}
		}

		return false;
	}, [customer, name, email, phoneNumber, notes]);

	const customerRef = useRef(customer);
	useEffect(() => {
		if (customer && customerRef.current !== customer) {
			customerRef.current = customer;

			onNameChange(null, customer.name);
			onEmailChange(null, customer.email ?? undefined);
			onPhoneNumberChange(null, customer.phoneNumber ?? undefined);
			onNotesChange(null, customer.notes ?? undefined);
		}
	}, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

	const [createCustomer, {loading: isCreating}] = useMutation(CREATE_CUSTOMER);
	const [updateCustomer, {loading: isUpdating}] = useMutation(UPDATE_CUSTOMER);

	const onSave = useCallback(async () => {
		const customerData = {
			name: name.trim(),
			email: email.trim(),
			phoneNumber: phoneNumber.trim(),
			notes: notes.trim(),
		};

		if (customer) {
			await updateCustomer({
				variables: {
					customerId: customer.id,
					customer: customerData,
				},
				refetchQueries: [GET_CUSTOMER],
			});
		} else {
			const {data} = await createCustomer({
				variables: {
					customer: customerData,
				},
				refetchQueries: [GET_CUSTOMER],
			});

			window.location.href = `/customers/${data?.createCustomer!}/edit`;
		}
	}, [createCustomer, updateCustomer, customer, name, email, phoneNumber, notes]);

	return (
		<Layout>
			<Layout.Header title={customer ? 'Edit Customer' : 'New Customer'}>
				<LoaderButton
					appearance='primary'
					disabled={saveDisabled}
					onClick={onSave}
					isLoading={isCreating || isUpdating}
					minWidth={80}
				>
					{customer ? 'Save' : 'Create'}
				</LoaderButton>
			</Layout.Header>
			<Layout.Body>
				{customerId && (
					<FormField label='Customer ID' maxWidth='300px'>
						{(props) => (
							<Input {...props} name='customerId' value={customerId!} disabled readOnly />
						)}
					</FormField>
				)}

				<FormField label='Name' maxWidth='300px'>
					{(props) => (
						<Input {...props} name='customerName' value={name} onChange={onNameChange} required />
					)}
				</FormField>

				<FormField label='Email' maxWidth='300px'>
					{(props) => (
						<Input
							{...props}
							name='customerEmail'
							type='email'
							value={email}
							onChange={onEmailChange}
						/>
					)}
				</FormField>

				<FormField label='Phone Number' maxWidth='300px'>
					{(props) => (
						<Input
							{...props}
							name='phoneNumber'
							value={phoneNumber}
							onChange={onPhoneNumberChange}
						/>
					)}
				</FormField>

				<FormField label='Notes'>
					{(props) => <Textarea {...props} name='notes' value={notes} onChange={onNotesChange} />}
				</FormField>
			</Layout.Body>
		</Layout>
	);
};

export default CustomerEdit;
