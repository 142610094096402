import {ApolloClient, InMemoryCache} from '@apollo/client';
import {
	type Category,
	type CategoryGroup,
	type PaymentAccount,
	type Project,
	type Tag,
	type Transaction,
} from './__generated__/graphql';

export const apolloClient = new ApolloClient({
	uri: '/api/graphql',
	cache: new InMemoryCache(),
});

export * from './__generated__/gql';
export * from './__generated__/graphql';

export type TableTransaction = Pick<Transaction, 'id' | 'date' | 'amount' | 'memo' | 'payee'> & {
	tags: Pick<Tag, 'id' | 'name' | 'color'>[];
	projects: Pick<Project, 'id' | 'name'>[];
	category: Pick<Category, 'id' | 'name'> & {
		categoryGroup?: Pick<CategoryGroup, 'id' | 'name'> | null | undefined;
	};
};

export type ExportTransaction = TableTransaction & {
	paymentAccount: Pick<PaymentAccount, 'id' | 'name'>;
};
