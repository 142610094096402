import {useQuery} from '@apollo/client';
import {Button, Icon} from '@sproutsocial/racine';
import {gql} from '../../graphql';
import Layout from '../Layout';
import ProjectsTable from './ProjectsTable';

const GET_PROJECTS = gql(/* GraphQL */ `
	query GetProjectList {
		projects {
			id
			name
			description
			startDate
			customer {
				id
				name
			}
			transactions {
				amount
			}
		}
	}
`);

const ProjectList = () => {
	const {data: {projects} = {}} = useQuery(GET_PROJECTS);

	return (
		<Layout>
			<Layout.Header title='Projects'>
				<Button appearance='primary' href='/projects/new'>
					<Icon name='plus' mr='space.300' />
					New Project
				</Button>
			</Layout.Header>
			<Layout.Body>
				<ProjectsTable projects={projects ?? []} showProfit />
			</Layout.Body>
		</Layout>
	);
};

export default ProjectList;
